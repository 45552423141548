import { SVGAttributes } from 'react';

const PostReactions = (props: SVGAttributes<SVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
        <g clipPath="url(#clip0_9283_6782)">
            <path d="M7.58333 11.9168V20.5835C7.58333 20.8708 7.4692 21.1464 7.26603 21.3495C7.06287 21.5527 6.78732 21.6668 6.5 21.6668H4.33333C4.04602 21.6668 3.77047 21.5527 3.5673 21.3495C3.36414 21.1464 3.25 20.8708 3.25 20.5835V13.0002C3.25 12.7128 3.36414 12.4373 3.5673 12.2341C3.77047 12.031 4.04602 11.9168 4.33333 11.9168H7.58333ZM7.58333 11.9168C8.73261 11.9168 9.83481 11.4603 10.6475 10.6476C11.4601 9.83497 11.9167 8.73277 11.9167 7.5835V6.50016C11.9167 5.92553 12.1449 5.37443 12.5513 4.9681C12.9576 4.56177 13.5087 4.3335 14.0833 4.3335C14.658 4.3335 15.2091 4.56177 15.6154 4.9681C16.0217 5.37443 16.25 5.92553 16.25 6.50016V11.9168H19.5C20.0746 11.9168 20.6257 12.1451 21.0321 12.5514C21.4384 12.9578 21.6667 13.5089 21.6667 14.0835L20.5833 19.5002C20.4275 20.1648 20.132 20.7354 19.7412 21.1262C19.3504 21.517 18.8856 21.7067 18.4167 21.6668H10.8333C9.97138 21.6668 9.14473 21.3244 8.53524 20.7149C7.92574 20.1054 7.58333 19.2788 7.58333 18.4168" stroke="#5079E4" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9283_6782">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default PostReactions;
