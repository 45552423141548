/* eslint-disable @typescript-eslint/naming-convention */
import { queryBuilder } from '@services/url/url';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import Config from 'config/config';
import CryptoJS from 'crypto-js';

import { AuthToken } from '@services/auth-token/authToken';
import { BaseClient } from './_baseClient';

const singleton         = Symbol();
const singletonEnforcer = Symbol();
const { api }           = Config;
const apiVersion        = 'v1';

function getUTCDateTime() {
    // convert date to UTC timezone (GMT)
    const now   = new Date();
    const year  = now.getUTCFullYear();
    const month = ('0' + (now.getUTCMonth() + 1)).slice(-2);
    const day   = ('0' + now.getUTCDate()).slice(-2);
    const hours = ('0' + now.getUTCHours()).slice(-2);

    return year + '-' + month + '-' + day + ' ' + hours;
}

function createToken() {
    const token        = AuthToken.getToken();
    const decodedToken = new AuthToken(token).getDecodedToken;
    const clientId     = decodedToken.site.name === 'dev' ? 'inkyfada' : decodedToken.site.name;



    const utcDate = getUTCDateTime();

    const tokenContent = `${clientId}_${utcDate}`;
    const md5Hash      = CryptoJS.MD5(tokenContent);

    return md5Hash;
}

class CSocialMediaAPIClient extends BaseClient {
    protected _dashboardEnv = '';

    private constructor(enforcer) {
        super();

        try {
            if (enforcer !== singletonEnforcer) {
                throw new Error('Cannot construct singleton');
            }

            this._dashboardEnv = AuthToken.getDashboardEnvironment();
            this._baseURL      = `${api.socialMediaApiDomain}/api/${apiVersion}`;

            const md5Hash      = createToken();

            this._config       = {
                headers: {
                    'Content-Type': 'application/ld+json',
                    Authorization: md5Hash.toString()
                }
            };
            this._initSession();
        } catch (e: unknown) {
            console.error(e);
        }
    }

    static get instance(): CSocialMediaAPIClient & AxiosInstance {
        // Try to get an efficient singleton
        if (!this[singleton]) {
            this[singleton] = new CSocialMediaAPIClient(singletonEnforcer);
        }

        return this[singleton];
    }

    getActiveProfiles<AxiosReturn = any>(clientId: string, config?: AxiosRequestConfig) {
        return this.get<AxiosReturn>(`/allActiveSocial/${clientId}`, { ...this._config, ...config });
    }

    getOverviewEngagement<Params extends Record<string, any> = any, AxiosReturn = any>(params: Params, config?: AxiosRequestConfig) {
        const clientId = params?.client_id ?? 'inkyfada';
        const url      = queryBuilder(`/social_media/engagement/${clientId}`, params);

        return this.get<AxiosReturn>(url, { ...this._config, ...config });
    }

    getOverview<Params extends Record<string, any> = any, AxiosReturn = any>(params: Params, config?: AxiosRequestConfig) {
        const url = queryBuilder('/social_media/overview', params);

        return this.get<AxiosReturn>(url, { ...this._config, ...config });
    }

    getSingle<Params extends { id: string; [key: string]: any }, AxiosReturn = any>(
        params: Params, config?: AxiosRequestConfig
    ) {
        const { id, ...rest } = params;
        const url             = queryBuilder(`/social_media/${id}`, rest);

        return this.get<AxiosReturn>(url, { ...this._config, ...config });
    }

    getAllPosts<Params extends Record<string, any> = any, AxiosReturn = any>(params: Params, config?: AxiosRequestConfig) {
        const { id, ...rest } = params;

        return this.get<AxiosReturn>(`/posts/${id}`, { ...this._config, ...config, params: rest });
    }

    getPost<Params extends Record<string, any> = any, AxiosReturn = any>(params: Params, config?: AxiosRequestConfig) {
        const { id, ...rest } = params;

        return this.get<AxiosReturn>(`/post/${id}`, { ...this._config, ...config, params: rest });
    }

    getProfiles<AxiosReturn = any>(clientId: string, config?: AxiosRequestConfig) {
        return this.get<AxiosReturn>(`/allActiveSocial/${clientId}`, { ...this._config, ...config });
    }

    addProfile<AxiosReturn = any, Data = any>(data: Data, config?: AxiosRequestConfig) {
        return this.post<AxiosReturn>('/new_social_media', { ...data, env: this._dashboardEnv }, { ...this._config, ...config });
    }

    deleteProfile<AxiosReturn = any>(data: { url: string; clientId: string }, config?: AxiosRequestConfig) {


        return this.get<AxiosReturn>(
            `/delete_social_media?clientId=${data.clientId}&url=${encodeURIComponent((data.url))}`,
            { ...this._config, ...config }
        );
    }
}

export const SocialMediaAPIClient = CSocialMediaAPIClient.instance;
