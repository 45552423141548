import { ChartLabelStyle, DefaultChartAnimation, DefaultTooltipLinePointerOptions } from 'constants/chart';
import { ThemeColors } from 'constants/colors';
import * as echarts from 'echarts';
import { symbolizeNumber } from 'services/conversion/conversion';

export const SMSProfileInteractionsChartColors = [
    ThemeColors.themeHighlightColor,
    ThemeColors.themeStatsColor15,
];
export const SMSProfileInteractionsChartOptions: echarts.EChartsOption = {
    grid: {
        left: 80,
        top: 10,
        right: 40,
        bottom: 70,
    },
    brush: {
        toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
        xAxisIndex: 0,
    },
    toolbox: { show: false },
    tooltip: DefaultTooltipLinePointerOptions,
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
        axisLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
                color: '#CFCECE',
                opacity: 0.5,
            },
        },
        axisLabel: ChartLabelStyle.axisLabel,
        axisTick: { show: false },
    },
    yAxis: {
        type: 'value',
        splitNumber: 4,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#CFCECE',
                type: 'dashed',
                opacity: 1,
            },
        },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
            ...ChartLabelStyle.axisLabel,
            formatter: (value) => symbolizeNumber(value, 3, { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: true }),
        },
    },
    series: {
        name: 'Interactions',
        smooth: true,
        data: [],
        type: 'line',
        lineStyle: { width: 1.5 },
        symbol: 'none',
        emphasis: {
            focus: 'series',
        },
        ...DefaultChartAnimation.serie.delay(0),
    },
    color: [
        ThemeColors.themeGrayColor,
        ThemeColors.themeGrayLighterColor,
    ],
    ...DefaultChartAnimation.global,
};
