export enum FacebookPostSort {
    mostRecent='mostRecent',
    leastRecent='leastRecent',
    mostReached='mostReached',
    leastReached='leastReached',
    mostEngagement='mostEngagement',
    leastEngagement='leastEngagement',
}

export enum FacebookPostTypes {
    none='none',
    all='all',
    photos='photos',
    videos='videos',
    status='status',
    reels='reels',
    links='links',
}
