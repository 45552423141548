import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageNav, SocialMediaProfilesMenu } from '@components/common';
import { DefaultAvatar } from '@constants/defaults';
import { SocialMediaLogos } from '@constants/socialMedia';
import PageWrapper from '@pages/base/PageWrapper';
import { SocialMediaSingleProfileItem } from '@pages/social-media/details/SocialMediaDetails';
import useFetchActiveProfiles from '@pages/social-media/details/useFetchActiveProfiles';
import useFetchNetworkData from '@pages/social-media/details/useFetchNetworkData';
import { DefinedRoutes } from '@routes/definedRoutes';
import { getSocialMediaSingleUrl } from '@routes/definedRoutesUtils';
import { capitalizeFirstLetter } from '@services/conversion/conversion';
import { isArrayEmpty } from '@services/validation/validation';
import { DataStatus } from '@shared/enum/dataStatus';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { SocialMediaSingleActiveProfiles } from '@shared/interfaces/social-media';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { useMemo } from 'react';

const NetworkTypeToLogoMapper = {
    [SocialMediaTypes.FACEBOOK]: SocialMediaLogos.facebook.filled,
    [SocialMediaTypes.INSTAGRAM]: SocialMediaLogos.instagram.filled,
    [SocialMediaTypes.TWITTER]: SocialMediaLogos.twitter.filled,
    [SocialMediaTypes.YOUTUBE]: SocialMediaLogos.youtube.filled,
};

export interface SinglesLayoutContextType {
    profiles:  SocialMediaSingleProfileItem[];
    currentProfile: SocialMediaSingleProfileItem;
    currentProfileId: string;
    currentNetworkType: SocialMediaTypes;

    activeProfiles: SocialMediaSingleActiveProfiles;
    activeProfilesStatus: DataStatus;
    hasActiveProfiles: boolean;

    networkData: ReturnType<typeof selectFromSocialMediaSingle.all>;
}

const SocialMediaSinglesLayout = () => {
    const navigate            = useNavigate();
    const { pathname, state } = useLocation();
    const { profileId }       = useParams();
    const currentNetworkType  = (state?.networkType as SocialMediaTypes) || SocialMediaTypes.UNKNOWN;

    const { activeProfiles, status: activeProfilesStatus } = useFetchActiveProfiles(profileId);
    const hasActiveProfiles                                = (
        activeProfilesStatus === DataStatus.SUCCESS &&
        !isArrayEmpty(activeProfiles) &&
        !!activeProfiles.find(profile => profile.status === true)
    );
    const profiles: SocialMediaSingleProfileItem[]         = isArrayEmpty(activeProfiles)
        ? []
        : activeProfiles.map((profile, idx) => profile.status === true ? ({
            id: String(idx+1),
            isSelected: profile.profileId === profileId,
            networkThumbnail: profile?.network ? NetworkTypeToLogoMapper?.[profile.network] : null,
            networkType: profile.network as SocialMediaTypes,
            profile: profile.name,
            profileId: profile.profileId,
            thumbnail: profile?.thumbnail || NetworkTypeToLogoMapper?.[profile.network] || DefaultAvatar,
            url: profile.url,
        }) : null).filter(Boolean);
    const currentProfile: SocialMediaSingleProfileItem     = profiles.find(profile => profile.isSelected);

    const { data } = useFetchNetworkData(profileId, hasActiveProfiles);

    const networkType = (() =>{
        if (currentNetworkType) {
            return currentNetworkType;
        }

        if (DefinedRoutes.socialMedia.singles.facebook.validator(pathname)) {
            return SocialMediaTypes.FACEBOOK;
        }

        if (DefinedRoutes.socialMedia.singles.instagram.validator(pathname)) {
            return SocialMediaTypes.INSTAGRAM;
        }

        if (DefinedRoutes.socialMedia.singles.tiktok.validator(pathname)) {
            return SocialMediaTypes.TIKTOK;
        }

        if (DefinedRoutes.socialMedia.singles.twitter.validator(pathname)) {
            return SocialMediaTypes.TWITTER;
        }

        return SocialMediaTypes.YOUTUBE;
    })();

    const isInAllPosts = useMemo(() => (
        DefinedRoutes.socialMedia.singles?.[networkType]?.posts?.validator?.(pathname)
        ?? false
    ), [networkType, pathname]);

    return (
        <PageWrapper
            wrapperId="dashboard_wrapper"
            pageTitle={`Social media | ${currentNetworkType}`}
            headerInfo={isInAllPosts ? (
                <PageNav
                    parent='Social media'
                    childrenPages={[
                        {
                            name: capitalizeFirstLetter(String(currentNetworkType)),
                            path: ''
                        },
                        {
                            name: 'All posts',
                            path: ''
                        },
                    ]}
                />
            ) : (
                <SocialMediaProfilesMenu
                    isLoading={activeProfilesStatus === DataStatus.LOADING}
                    onChangeSelectedProfile={(profile) => {
                        const pathToDetails = getSocialMediaSingleUrl(profile.networkType, profile.profileId);

                        navigate(pathToDetails);
                    }}
                    profiles={profiles}
                />
            )}
            disableDownloadReport
        >
            <div>
                <Outlet
                    context={{
                        profiles,
                        currentProfile,
                        currentProfileId: profileId,
                        currentNetworkType,

                        activeProfiles,
                        activeProfilesStatus,
                        hasActiveProfiles,

                        networkData: data,
                    } as SinglesLayoutContextType}
                />
            </div>
        </PageWrapper>
    );
};

export default SocialMediaSinglesLayout;
