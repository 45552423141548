import { SVGAttributes } from 'react';

export const Interactions = (props: SVGAttributes<SVGElement> ) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_10407_3419)">
            <path
                d="M6.66406 10.8333V3.75C6.66406 3.41848 6.79576 3.10054 7.03018 2.86612C7.2646 2.6317 7.58254 2.5 7.91406 2.5C8.24558 2.5 8.56353 2.6317 8.79795 2.86612C9.03237 3.10054 9.16406 3.41848 9.16406 3.75V10"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.16406 9.58366V7.91699C9.16406 7.58547 9.29576 7.26753 9.53018 7.03311C9.7646 6.79869 10.0825 6.66699 10.4141 6.66699C10.7456 6.66699 11.0635 6.79869 11.2979 7.03311C11.5324 7.26753 11.6641 7.58547 11.6641 7.91699V10.0003"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6641 8.75C11.6641 8.41848 11.7958 8.10054 12.0302 7.86612C12.2646 7.6317 12.5825 7.5 12.9141 7.5C13.2456 7.5 13.5635 7.6317 13.7979 7.86612C14.0324 8.10054 14.1641 8.41848 14.1641 8.75V10"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1697 9.58301C14.1697 9.25149 14.3014 8.93354 14.5359 8.69912C14.7703 8.4647 15.0882 8.33301 15.4197 8.33301C15.7513 8.33301 16.0692 8.4647 16.3036 8.69912C16.5381 8.93354 16.6697 9.25149 16.6697 9.58301V13.333C16.6697 14.6591 16.143 15.9309 15.2053 16.8685C14.2676 17.8062 12.9958 18.333 11.6697 18.333H10.0031H10.1764C9.34836 18.3331 8.53324 18.1276 7.80424 17.7349C7.07523 17.3422 6.45518 16.7746 5.99975 16.083L5.83642 15.833C5.57642 15.4338 4.66392 13.843 3.09808 11.0597C2.93846 10.776 2.89582 10.4411 2.97923 10.1265C3.06265 9.81179 3.26554 9.54207 3.54475 9.37467C3.84215 9.19623 4.19064 9.12227 4.53488 9.16455C4.87912 9.20682 5.19937 9.36291 5.44475 9.60801L6.66975 10.833"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.16927 2.50033L3.33594 1.66699"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.33333 5.83301H2.5"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6641 2.50033L12.4974 1.66699"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 5H13.3333"
                stroke="#5079E4"
                strokeWidth={1.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_10407_3419">
                <rect width={20} height={20} fill="white" />
            </clipPath>
        </defs>
    </svg>
);
