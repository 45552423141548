import { SVGAttributes } from 'react';

const PostClicks = (props: SVGAttributes<SVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
        <g clipPath="url(#clip0_9283_6785)">
            <path d="M8.66406 14.0833V4.875C8.66406 4.44402 8.83527 4.0307 9.14001 3.72595C9.44476 3.4212 9.85809 3.25 10.2891 3.25C10.72 3.25 11.1334 3.4212 11.4381 3.72595C11.7429 4.0307 11.9141 4.44402 11.9141 4.875V13" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.9141 12.4582V10.2915C11.9141 9.86053 12.0853 9.4472 12.39 9.14246C12.6948 8.83771 13.1081 8.6665 13.5391 8.6665C13.97 8.6665 14.3834 8.83771 14.6881 9.14246C14.9929 9.4472 15.1641 9.86053 15.1641 10.2915V12.9998" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.1641 11.375C15.1641 10.944 15.3353 10.5307 15.64 10.226C15.9448 9.9212 16.3581 9.75 16.7891 9.75C17.22 9.75 17.6334 9.9212 17.9381 10.226C18.2429 10.5307 18.4141 10.944 18.4141 11.375V13" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.4144 12.4585C18.4144 12.0275 18.5856 11.6142 18.8904 11.3094C19.1951 11.0047 19.6084 10.8335 20.0394 10.8335C20.4704 10.8335 20.8837 11.0047 21.1885 11.3094C21.4932 11.6142 21.6644 12.0275 21.6644 12.4585V17.3335C21.6644 19.0574 20.9796 20.7107 19.7606 21.9297C18.5416 23.1487 16.8883 23.8335 15.1644 23.8335H12.9978H13.2231C12.1466 23.8337 11.087 23.5665 10.1393 23.056C9.19155 22.5454 8.38548 21.8075 7.79342 20.9085L7.58109 20.5835C7.24309 20.0646 6.05684 17.9965 4.02126 14.3782C3.81374 14.0093 3.75831 13.5741 3.86675 13.165C3.9752 12.7559 4.23895 12.4053 4.60192 12.1877C4.98854 11.9557 5.44159 11.8595 5.8891 11.9145C6.33661 11.9695 6.75293 12.1724 7.07192 12.491L8.66442 14.0835" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.41927 3.24984L4.33594 2.1665" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.33333 7.5835H3.25" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.1641 3.24984L16.2474 2.1665" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.25 6.5H17.3333" stroke="#5079E4" strokeWidth="1.63" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9283_6785">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default PostClicks;
