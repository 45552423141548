import { ReactNode } from 'react';

import { symbolizeNumber } from '@services/conversion/conversion';
import { neitherNullNorUndefined } from '@services/validation/validation';

import './FPPDetailsSection.scss';

export interface FacebookPostPopupSlideItemMetricGroupItem {
    icon: ReactNode;
    title: string;
    value: number | string;
    formatNumber?: boolean;
}
export interface FacebookPostPopupSlideItemMetricGroup {
    title: string;
    items: FacebookPostPopupSlideItemMetricGroupItem[];
}

type Props = FacebookPostPopupSlideItemMetricGroup & {
    addSeparator?: boolean;
};

const FPPDetailsSection = ({ title, items, addSeparator: addSeparator = false }: Props) => (
    <section className="fppds-container">
        <h4>{title}</h4>

        <div className="fppds-items">
            {items.map((props, idx) => (
                <Item
                    key={`${title.slice(0, 5)}-${idx}`}
                    {...props}
                />
            ))}
        </div>

        {addSeparator && <div className="fppds-separator"/>}
    </section>
);

type ItemProps = FacebookPostPopupSlideItemMetricGroupItem;

const Item = ({ icon, title, value, formatNumber }: ItemProps) => (
    <div className="fppds-item">
        <div className="fppds-head">
            {icon}
            <p>{title}</p>
        </div>
        <h5>{neitherNullNorUndefined(formatNumber) && formatNumber === false ? value : symbolizeNumber(Number(value || 0), 1, {
            convertToComma: true,
            shouldRound: true,
            ignoreFirstSixDigits: true
        })}</h5>
    </div>
);

export default FPPDetailsSection;
