import { useEffect, useState } from 'react';

import { Plus2Sm } from '@components/svg/Plus2Sm';
import Loader from '@components/widgets/loader/Loader';
import { setPageDownloadForceLoad } from '@store/page-download/pageDownload.slice';
import Config from 'config/config';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './PageFilterReport.sass';

const { api } = Config;

function PageFilterReport() {
    const [isLoading, setIsLoading]  = useState(false);
    const [isReady, setIsReady]      = useState(false);
    const [src, setSrc]              = useState('');
    const [timer, setTimer]          = useState(null);
    const [hasClicked,setHasClicked] = useState(false);


    const { username, dateRange, dataIsLoading, pageDownload } = useAppSelector((state) => ({
        username: state?.authenticationReducer?.user?.username,
        dateRange: state.datePickerReducer,
        dataIsLoading: state.viewsReducer.audienceIsLoading,
        pageDownload: state?.pageDownloadReducer
    }));
    const domain                                               = api.apiDomain.replace('{username}', username);

    const dispatch = useAppDispatch();

    const handleClick = () => {
        if (isLoading) { return; }

        setIsLoading(true);

        const ref = window.appWrapperRef;

        if (ref.current === null) {
            return;
        }

        setHasClicked(true);
        dispatch(setPageDownloadForceLoad(true));
    };

    useEffect(() => {
        if (!isLoading) {
            setIsReady(false);
        }

    }, [dateRange.current, dateRange.previous]);

    useEffect(()=>{
        console.log(pageDownload.pageLoaded);
    },[pageDownload.pageLoaded]);


    useEffect(() => {
        const { pageLoaded } = pageDownload;

        if (!hasClicked) {return;}

        if (pageLoaded === false) {
            if (timer) {
                clearTimeout(timer);
                setTimer(null);
            }
        }

        if (pageLoaded === true && !timer) {
            const newTimer = setTimeout(() => {
                const ref = window.appWrapperRef;

                if (ref.current === null) {
                    return;
                }

                html2canvas(ref.current,{
                    allowTaint: true,
                    useCORS: true
                }).then((canvas: HTMLCanvasElement) => {
                    setHasClicked(false);
                    setIsLoading(false);
                    dispatch(setPageDownloadForceLoad(false));

                    const imgData = canvas.toDataURL('image/png');

                    const imgWidth  = canvas.width;
                    const imgHeight = canvas.height;
                    const pdf       = new jsPDF(null, 'px',[imgWidth + 60, imgHeight + 30]);

                    pdf.addImage(imgData, 'PNG', 30, 15, imgWidth, imgHeight);
                    pdf.save('report.pdf');

                    return null;
                });
            }, 3000);

            setTimer(newTimer);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [pageDownload.pageLoaded,hasClicked]);

    return (
        <div className='filter-report-wrapper'>
            <>
                {
                    !isLoading && isReady && (
                        <>
                            <a className='filter-report-download-pdf --seePdf' href={domain + src} target='_blank'>
                                See report
                            </a>
                        </>
                    )
                }

                {
                    !isLoading && !isReady && (
                        <div onClick={handleClick} className={'filter-report-download-pdf --downloadPdf' + (dataIsLoading ? ' _disabled' : '')}>
                            <span className='svg-wrapper'>
                                <Plus2Sm />
                            </span>
                            <span>
                                Generate report
                            </span>
                        </div>
                    )
                }

                {
                    isLoading && !isReady && (
                        <div className='filter-report-download-pdf --loadingPdf'>
                            <Loader />
                        </div>
                    )
                }
            </>
        </div>
    );
}

export default PageFilterReport;
