import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
    stroke?: string;
}

export const Reach = ({ stroke = '#5079E4', ...props }: Props) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
        <g clipPath="url(#clip0_9686_1387)">
            <path d="M14.6641 16.0001C14.6641 16.3537 14.8045 16.6928 15.0546 16.9429C15.3046 17.1929 15.6438 17.3334 15.9974 17.3334C16.351 17.3334 16.6902 17.1929 16.9402 16.9429C17.1903 16.6928 17.3307 16.3537 17.3307 16.0001C17.3307 15.6465 17.1903 15.3073 16.9402 15.0573C16.6902 14.8072 16.351 14.6667 15.9974 14.6667C15.6438 14.6667 15.3046 14.8072 15.0546 15.0573C14.8045 15.3073 14.6641 15.6465 14.6641 16.0001Z" stroke={stroke} strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.0026 9.33325C14.6841 9.33325 13.3951 9.72424 12.2988 10.4568C11.2025 11.1893 10.348 12.2305 9.84341 13.4487C9.33883 14.6669 9.2068 16.0073 9.46404 17.3005C9.72127 18.5937 10.3562 19.7816 11.2886 20.714C12.2209 21.6463 13.4088 22.2813 14.702 22.5385C15.9952 22.7957 17.3357 22.6637 18.5538 22.1591C19.772 21.6545 20.8132 20.8 21.5457 19.7037C22.2783 18.6074 22.6693 17.3185 22.6693 15.9999" stroke={stroke} strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.3379 4.07336C14.866 3.79604 12.369 4.29373 10.1923 5.49759C8.01558 6.70144 6.26679 8.55196 5.18779 10.7932C4.1088 13.0344 3.75293 15.5555 4.16942 18.0078C4.58591 20.4601 5.75417 22.7224 7.51259 24.4817C9.27102 26.241 11.5327 27.4104 13.9848 27.8281C16.4369 28.2458 18.9582 27.8913 21.1999 26.8134C23.4417 25.7355 25.2931 23.9877 26.498 21.8116C27.703 19.6355 28.2019 17.1387 27.9259 14.6667" stroke={stroke} strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 8V12H24L28 8H24V4L20 8Z" stroke={stroke} strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 12L16 16" stroke={stroke} strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9686_1387">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
