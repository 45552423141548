/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { Group, Option } from 'react-dropdown';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperDTO } from 'swiper/types';

import 'swiper/css';

import { IDropdown, IDropdownItem } from '@components/common';
import { ArrowRightShort } from '@components/svg';
import { SocialMediaLogos } from '@constants/socialMedia';
import { FacebookPostSort, FacebookPostTypes } from '@shared/enum';

import { LoaderWrapper } from '@components/widgets';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DefinedRoutes } from '@routes/definedRoutes';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import DateInstance from '@services/date/date';
import { DataStatus } from '@shared/enum/dataStatus';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { fetchSocialMediaSingleAllPosts } from '@store/social-media/single/SocialMediaSingle.thunk';
import './SMSFacebookPostsSwiper.scss';
import SMSFacebookPostItem from './post-item/SMSFacebookPostItem';

// const posts = new Array(10).fill({
//     id: Math.random() * 1000,
//     url: 'https://google.com',
//     image: NoImage,
//     publishDate: '27 August 2023 - 11:24 AM',
//     title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, consectetur! Veniam, perferendis. Dolore, sit blanditiis, beatae assumenda reiciendis ipsam nesciunt recusandae omnis autem officia nihil perferendis, voluptatibus enim unde odio voluptatum laboriosam vero laudantium incidunt libero? Quos voluptates veritatis dolore accusamus id reprehenderit impedit suscipit sapiente vero omnis nostrum architecto quidem quod temporibus, soluta fuga adipisci et, neque eveniet fugit similique asperiores, repellendus deleniti culpa. Eos repellat quidem sit vitae animi reprehenderit ad iure? Tenetur, sit eligendi. Iusto iste nostrum facilis in pariatur ab veniam quos perferendis ducimus illo temporibus, odio soluta eaque repellendus qui quasi provident adipisci ea! Quibusdam quasi vitae at voluptatem nam dolore laboriosam accusamus itaque eius temporibus voluptate consequuntur dignissimos ullam dolorem necessitatibus id fuga animi, doloribus nobis tempore, dicta sit exercitationem! Voluptatem minima et iure. Facilis quisquam laudantium nisi amet iusto eius. Iste id, eum eligendi omnis et veritatis est! Aliquid excepturi alias perferendis non, omnis consectetur laborum modi eaque doloremque itaque enim dolorem. Nam consectetur ea sint, assumenda a impedit eveniet blanditiis fugiat placeat cum, magni ad odit! Atque porro quidem modi aut id nostrum alias sint nesciunt nemo saepe? Eligendi, excepturi ex fugit earum in nisi praesentium natus dolores magni libero. Recusandae earum eum sequi repellat vitae ipsum ipsa, soluta, consectetur dolorum molestiae, obcaecati laborum. Ratione reiciendis sint, aperiam inventore dolor a deleniti nesciunt repellendus ab quam. Impedit voluptatibus reprehenderit, dolorum totam praesentium ex eum architecto officia obcaecati facilis saepe nihil. Aliquam cum pariatur reiciendis voluptatem dolorum facere blanditiis et doloremque animi cupiditate.',
//     likes: 149,
//     comments: 22,
//     shares: 26
// });

const sortMapper                                = {
    [FacebookPostSort.mostRecent]: 'Sort by most recent',
    [FacebookPostSort.leastRecent]: 'Sort by least recent',
    [FacebookPostSort.mostReached]: 'Sort by most reached',
    [FacebookPostSort.leastReached]: 'Sort by least reached',
    [FacebookPostSort.mostEngagement]: 'Sort by most engagement',
    [FacebookPostSort.leastEngagement]: 'Sort by least enagegement',
};
const sortDropdownDefault                       = {
    label: <IDropdownItem title={sortMapper[FacebookPostSort.mostRecent]} image={SocialMediaLogos.facebook.rounded} />,
    value: FacebookPostSort.mostRecent,
};
const sortDropdown: (string | Option | Group)[] = Object.keys(sortMapper).map(key => ({
    label: <IDropdownItem title={sortMapper[key]} />,
    value: key,
}));

const postTypeMapper                                = {
    [FacebookPostTypes.all]: 'All',
    [FacebookPostTypes.photos]: 'Photos',
    [FacebookPostTypes.videos]: 'Videos',
    [FacebookPostTypes.status]: 'Status',
    [FacebookPostTypes.reels]: 'Reels',
    [FacebookPostTypes.links]: 'links',
};
const postTypeDropdownDefault                       = {
    label: <IDropdownItem title={'Sort post types'} />,
    value: FacebookPostTypes.none,
};
const postTypeDropdown: (string | Option | Group)[] = Object.keys(postTypeMapper).map(key => ({
    label: <IDropdownItem title={postTypeMapper[key]} />,
    value: key,
}));

interface Props {
    profileId: string;
    networkType: SocialMediaTypes;
}

const SMSFacebookPostsSwiper = ({ profileId, networkType }: Props) => {
    const [swiper, setSwiper]            = useState<SwiperDTO>(null);
    const [sort, setSort]                = useState<typeof sortDropdownDefault>(sortDropdownDefault);
    const [postType, setPostType]        = useState<typeof postTypeDropdownDefault>(postTypeDropdownDefault);
    const { dates, data: posts, status } = useAppSelector((state) => ({
        ...selectFromDatepicker.dates(state),
        ...selectFromSocialMediaSingle.allPosts(state),
    }));
    const dispatch                       = useAppDispatch();

    useEffect(() => {
        const source     = generateNewCancelTokenSource();
        const [from, to] = dates;

        dispatch(fetchSocialMediaSingleAllPosts({
            data: {
                id: profileId,
                start_date: String(from),
                date_end: String(to),
                page: 1,
                limit: 20,
                sortby: sort.value,
                type: postType.value,
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => source.cancel();
    }, [dates, profileId, sort, postType]);

    const handleTransitionToRight = () => swiper.slideNext();
    const handleTransitionToLeft  = () => swiper.slidePrev();

    return (
        <>
            {/* <FacebookPostPopup /> */}

            <div className='smsfp-wrapper'>
                {status === DataStatus.LOADING && <LoaderWrapper />}

                <div className="smsfp-filters">
                    <IDropdown
                        className='smsfp-sort-filter'
                        value={sort}
                        options={sortDropdown}
                        onChange={(option) => {
                            setSort({
                                label: (
                                    <IDropdownItem
                                        title={sortMapper[option.value]}
                                        image={SocialMediaLogos.facebook.rounded}
                                    />
                                ),
                                value: option.value as FacebookPostSort,
                            });
                        }}
                    />

                    <IDropdown
                        className='smsfp-post-type-filter'
                        value={postType}
                        options={postTypeDropdown}
                        onChange={(option) => {
                            setPostType(option as typeof postTypeDropdownDefault);
                        }}
                    />
                </div>


                <div className="smsfp-swiper">
                    <div onClick={handleTransitionToLeft} className="smsfp-swiper-control --leftControl">
                        <ArrowRightShort />
                    </div>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        onSwiper={setSwiper}
                        className="smsfp-container"
                    >

                        {posts?.map((post, idx) => (
                            <SwiperSlide className="smsfp-item-wrapper" key={`${ post.id }-${idx}`}>
                                <SMSFacebookPostItem
                                    canClick={false}
                                    id={String(post.id)}
                                    comments={post.comments}
                                    image={post.image}
                                    likes={post.reactions}
                                    publishDate={DateInstance.formatDate({
                                        date: DateInstance.newDate(post.date),
                                        format: 'dd MMMM yyyy - hh:mm a',
                                    })}
                                    shares={post.shares}
                                    title={post.message}
                                />
                            </SwiperSlide>
                        ))}


                    </Swiper>
                    <div onClick={handleTransitionToRight} className="smsfp-swiper-control --rightControl">
                        <ArrowRightShort />
                    </div>
                </div>

                <NavButton profileId={profileId} networkType={networkType}>
                See all posts
                </NavButton>
            </div>
        </>
    );
};

interface NavButtonProps {
    profileId: string;
    networkType: SocialMediaTypes;
    text?: string;
    children?: React.ReactNode;
}

const NavButton = ({ profileId, networkType, text, children }: NavButtonProps) => (
    <Link
        to={DefinedRoutes.socialMedia.singles[networkType].posts.getPath(profileId)}
        state={{ networkType }}
        className="smsfp-button"
    >
        {text ?? children ?? 'N/A'}
    </Link>
);

export default SMSFacebookPostsSwiper;
