import { useEffect, useMemo, useState } from 'react';

import { KeyboardArrowRightMedium } from 'components/svg/arrows/KeyboardArrowRightMedium';

import { pagination } from './';

interface Props {
    nbPages: number;
    /** page index to manually select */
    updateCurrentPage?: number;
    onCurrentPageChange?: (value: number) => void;
    resetCurrentPage?: boolean;
}

const TablePagination = ({
    nbPages,
    onCurrentPageChange,
    resetCurrentPage,
}: Props) => {
    const [pages, setPages] = useState(
        [...Array(nbPages).keys()]?.map((number, index) => ({
            page: number + 1,
            current: index === 0,
        })),
    );

    useEffect(() => {
        setPages(
            [...Array(nbPages).keys()]?.map((number, index) => ({
                page: number + 1,
                current: index === 0,
            })),
        );
    }, [nbPages, resetCurrentPage]);

    const toShow = useMemo(() => {
        if (!pages.length) {
            return null;
        }

        const selected = pages.find((item) => item.current).page;
        const last     = pages[pages.length - 1].page;

        return pagination(selected, last);
    }, [pages]);

    function handlePaginationClick(index) {
        return new Promise<number>((resolve) => {
            let selectedPage: number = null;

            setPages((prev) => {
                const result = prev.map((item, idx) => {
                    if (idx === index) {
                        selectedPage = item.page;
                        item.current = true;
                    } else {
                        item.current = false;
                    }

                    return item;
                });

                resolve(selectedPage);

                return result;
            });
        }).then((selectedPage) => {
            if (selectedPage != null) {
                onCurrentPageChange?.(selectedPage);
            }
        });
    }

    const disabled = useMemo(() => {
        const selectedPage = currentPage(pages);
        const tmp          = { next: false, prev: false };

        if (selectedPage === 1) {
            tmp.prev = true;
        } else {
            tmp.prev = false;
        }

        if (selectedPage === pages.length) {
            tmp.next = true;
        } else {
            tmp.next = false;
        }

        return tmp;
    }, [pages]);

    return (
        <div className="cbt-table-pag">
            <div
                className={'pag-arrow' + (disabled.prev ? ' pag-disabled' : '')}
                onClick={() =>
                    prevPage(
                        setPages,
                        disabled,
                        pages,
                        nbPages,
                        onCurrentPageChange,
                    )
                }
            >
                <KeyboardArrowRightMedium
                    style={{ transform: 'rotate(-180deg)' }}
                />
            </div>

            {pages.map((item, index) => {
                let value: number | string = item.page;
                let className              = '';
                const isActive             = item.current ? ' active' : '';

                if (toShow[index] === '...') {
                    value     = '...';
                    className = '--pag-more';
                } else if (!toShow.includes(item.page)) {
                    return;
                }

                return (
                    <div
                        key={index}
                        className={'pag-btn' + className + isActive}
                        onClick={() => {
                            if (!className) {
                                handlePaginationClick(index);
                            }
                        }}
                    >
                        {value}
                    </div>
                );
            })}

            <div
                className={'pag-arrow' + (disabled.next ? ' pag-disabled' : '')}
                onClick={() =>
                    nextPage(
                        setPages,
                        disabled,
                        pages,
                        nbPages,
                        onCurrentPageChange,
                    )
                }
            >
                <KeyboardArrowRightMedium />
            </div>
        </div>
    );
};

const currentPage = (pages) =>
    pages.find((page) => {
        if (page.current) {
            return page;
        }
    })?.page;

const nextPage = (setPages, disabled, pages, nbPages, onCurrentPageChange) => {
    if (disabled.next) {
        return;
    }

    let selectedPage = currentPage(pages);

    if (selectedPage + 1 > nbPages) {
        selectedPage = 1;
    } else {
        selectedPage += 1;
    }

    onCurrentPageChange(selectedPage);

    setPages((prev) =>
        prev.map((page) => {
            if (page.page === selectedPage) {
                page.current = true;
            } else {
                page.current = false;
            }

            return page;
        }),
    );
};

const prevPage = (setPages, disabled, pages, nbPages, onCurrentPageChange) => {
    if (disabled.prev) {
        return;
    }

    let selectedPage = currentPage(pages);

    if (selectedPage - 1 === 0) {
        selectedPage = nbPages;
    } else {
        selectedPage -= 1;
    }

    onCurrentPageChange(selectedPage);

    setPages((prev) =>
        prev.map((page) => {
            if (page.page === selectedPage) {
                page.current = true;
            } else {
                page.current = false;
            }

            return page;
        }),
    );
};

export default TablePagination;
