import { ThemeColors } from 'constants/colors';
import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

const TablePostVue = ({ isActive, ...rest }: BaseSvgComponentProps) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"  {...rest}>
        <path
            d="M6.75 0.25C7.32464 0.25 7.87574 0.478273 8.28206 0.884602C8.68839 1.29093 8.91667 1.84203 8.91667 2.41667V6.75C8.91667 7.32464 8.68839 7.87574 8.28206 8.28206C7.87574 8.68839 7.32464 8.91667 6.75 8.91667H2.41667C1.84203 8.91667 1.29093 8.68839 0.884602 8.28206C0.478273 7.87574 0.25 7.32464 0.25 6.75V2.41667C0.25 1.84203 0.478273 1.29093 0.884602 0.884602C1.29093 0.478273 1.84203 0.25 2.41667 0.25H6.75Z"
            fill={isActive ? ThemeColors.themeStatsColor2 : ThemeColors.inputText}
        />
        <path
            d="M17.5859 0.25C18.1606 0.25 18.7117 0.478273 19.118 0.884602C19.5243 1.29093 19.7526 1.84203 19.7526 2.41667V6.75C19.7526 7.32464 19.5243 7.87574 19.118 8.28206C18.7117 8.68839 18.1606 8.91667 17.5859 8.91667H13.2526C12.678 8.91667 12.1269 8.68839 11.7205 8.28206C11.3142 7.87574 11.0859 7.32464 11.0859 6.75V2.41667C11.0859 1.84203 11.3142 1.29093 11.7205 0.884602C12.1269 0.478273 12.678 0.25 13.2526 0.25H17.5859Z"
            fill={isActive ? ThemeColors.themeStatsColor2 : ThemeColors.inputText}
        />
        <path
            d="M6.75 11.0835C7.32464 11.0835 7.87574 11.3118 8.28206 11.7181C8.68839 12.1244 8.91667 12.6755 8.91667 13.2502V17.5835C8.91667 18.1581 8.68839 18.7092 8.28206 19.1156C7.87574 19.5219 7.32464 19.7502 6.75 19.7502H2.41667C1.84203 19.7502 1.29093 19.5219 0.884602 19.1156C0.478273 18.7092 0.25 18.1581 0.25 17.5835V13.2502C0.25 12.6755 0.478273 12.1244 0.884602 11.7181C1.29093 11.3118 1.84203 11.0835 2.41667 11.0835H6.75Z"
            fill={isActive ? ThemeColors.themeStatsColor2 : ThemeColors.inputText}
        />
        <path
            d="M17.5859 11.0835C18.1606 11.0835 18.7117 11.3118 19.118 11.7181C19.5243 12.1244 19.7526 12.6755 19.7526 13.2502V17.5835C19.7526 18.1581 19.5243 18.7092 19.118 19.1156C18.7117 19.5219 18.1606 19.7502 17.5859 19.7502H13.2526C12.678 19.7502 12.1269 19.5219 11.7205 19.1156C11.3142 18.7092 11.0859 18.1581 11.0859 17.5835V13.2502C11.0859 12.6755 11.3142 12.1244 11.7205 11.7181C12.1269 11.3118 12.678 11.0835 13.2526 11.0835H17.5859Z"
            fill={isActive ? ThemeColors.themeStatsColor2 : ThemeColors.inputText}
        />
    </svg>
);

export default TablePostVue;
