import { BrowserRouter } from 'react-router-dom';

import AppNavItemDropdownPortalContainer from '@components/common/navs/app-nav/nav-item-dropdown/AppNavItemDropdownPortalContainer';
import ScrollRestore from '@components/common/scroll-restore/ScrollRestore';
import PopupContainer from '@components/widgets/popup/PopupContainer';
import SiteLoader from '@components/widgets/site-loader/SiteLoader';
import ErrorBoundary from '@errors/error-boundary/ErrorBoundary';
import DashboardLayout from '@layouts/dashboard/DashboardLayout';

import FacebookPostPopupContainer from '@features/social-media/single/facebook-post-popup/FacebookPostPopupContainer';
import { useState } from 'react';
import './MainLayout.sass';

function MainLayout() {
    const [shouldReload, setShouldReload] = useState(false);

    const handleClick = () => setShouldReload(!shouldReload);

    return (
        <main className="app">
            <BrowserRouter
            // forceReload={shouldReload}
            >
                <ErrorBoundary>
                    <PopupContainer />
                    <FacebookPostPopupContainer />
                    <AppNavItemDropdownPortalContainer />

                    <div className="app-clip"></div>
                    {/* <button onClick={handleClick} type='button'>reload</button> */}
                    <ScrollRestore />

                    <DashboardLayout />

                    <SiteLoader />
                </ErrorBoundary>
            </BrowserRouter>
        </main>
    );
}

export default MainLayout;
