/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';

import { Table } from '@components/common';
import { useUpdateEffect } from '@hooks/index';
import { FacebookPostTypes } from '@shared/enum';

import './FacebookAllPostsTable.scss';
import useFacebookAllPostsTable from './useFacebookAllPostsTable';

interface Props {
    postType: FacebookPostTypes;
    profileId: string;

    nbResultsPerPage: number;
    currentPage: number;
    resetCurrentPage: boolean;

    setNbResultsPerPage: (value: number) => void;
    setCurrentPage: (value: number) => void;
    toggleResetCurrentPage: (value: boolean) => void;
}

const FacebookAllPostsTable = ({
    nbResultsPerPage,
    currentPage,
    resetCurrentPage,

    setNbResultsPerPage,
    setCurrentPage,
    toggleResetCurrentPage,

    postType,
    profileId
}: Props) => {
    const [sort, setSort]                            = useState({ with: 'publishDate', reversed: false });

    useUpdateEffect(() => {
        toggleResetCurrentPage(!resetCurrentPage);
    }, [sort]);

    const { nbPages, bodyItems } = useFacebookAllPostsTable({
        limit: nbResultsPerPage,
        offset: nbResultsPerPage * currentPage - nbResultsPerPage,
        sort,
        postType,
        profileId,
    });

    return (
        <div className="fapt-wrapper">
            <Table
                setNbResults={(nbResults) => setNbResultsPerPage(nbResults)}

                onCurrentPageChange={(currentPage) => setCurrentPage(currentPage)}
                resetCurrentPage={resetCurrentPage}
                nbPages={nbPages}

                onOrderChange={(orderKey: string, isOrderReversed: boolean) => {
                    setSort({ with: OrderToSortingMapper?.[orderKey], reversed: isOrderReversed });
                }}
                defaultOrder={1}

                goToLinkWhenClickedOnTdFirstChild
                itemsNotBold
                headerItems={Object.keys(OrderToSortingMapper)}
                bodyItems={bodyItems}

                emptyBodyItemsMessage={'No Results'}

                // isLoading={isLoading}
                theadClass={'cbt-table-head'}
                tbodyClass={'cbt-table-body'}
            />
        </div>
    );
};

const OrderToSortingMapper = {
    Title: 'title',
    'Publication date': 'publishDate',
    Reactions: 'reactions',
    Comments: 'comments',
    Shares: 'shares',
    Impressions: 'impressions',
    'Engagement rate': 'engagementRate',
};

export default FacebookAllPostsTable;
