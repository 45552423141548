import { SVGAttributes } from 'react';

const PostComments = (props: SVGAttributes<SVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
        <g clipPath="url(#clip0_9283_6777)">
            <path d="M8.66406 9.75H17.3307" stroke="#5079E4" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.66406 14.0835H15.1641" stroke="#5079E4" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.5 4.3335C20.362 4.3335 21.1886 4.67591 21.7981 5.2854C22.4076 5.89489 22.75 6.72154 22.75 7.5835V16.2502C22.75 17.1121 22.4076 17.9388 21.7981 18.5483C21.1886 19.1578 20.362 19.5002 19.5 19.5002H14.0833L8.66667 22.7502V19.5002H6.5C5.63805 19.5002 4.8114 19.1578 4.2019 18.5483C3.59241 17.9388 3.25 17.1121 3.25 16.2502V7.5835C3.25 6.72154 3.59241 5.89489 4.2019 5.2854C4.8114 4.67591 5.63805 4.3335 6.5 4.3335H19.5Z" stroke="#5079E4" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9283_6777">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default PostComments;
