/* eslint-disable @typescript-eslint/naming-convention */
declare type Devices = '280' | '330' | '375' | '414' | '480' | '768' | '800' | '992' | '1024' | '1280' | '1366' | '1440' | '1536' | '1600' | '1920';

export class WidthDetector {
    prefix = 1;
    devices: { [key in Devices]: boolean } = {
        280: window.matchMedia('(max-width: 281px)').matches,
        330: window.matchMedia('(max-width: 331px)').matches,
        375: window.matchMedia('(max-width: 376px)').matches,
        414: window.matchMedia('(max-width: 415px)').matches,
        480: window.matchMedia('(max-width: 481px)').matches,
        768: window.matchMedia('(max-width: 769px)').matches,
        800: window.matchMedia('(max-width: 801px)').matches,
        992: window.matchMedia('(max-width: 993px)').matches,
        1024: window.matchMedia('(max-width: 1025px)').matches,
        1280: window.matchMedia('(max-width: 1281px)').matches,
        1366: window.matchMedia('(max-width: 1367px)').matches,
        1440: window.matchMedia('(max-width: 1441px)').matches,
        1536: window.matchMedia('(max-width: 1537px)').matches,
        1600: window.matchMedia('(max-width: 1601px)').matches,
        1920: window.matchMedia('(max-width: 1921px)').matches,
    };

    getCurrentDevice(): Devices | 'unknown' {
        const result = Object
            .entries(this.devices)
            .find(([, condition]) => condition) as [Devices, boolean];

        return result && result?.length ? result?.[0] : 'unknown';
    }

    getDevice(device: keyof typeof this.devices) {
        return this.devices?.[device];
    }

    getDevices() {
        return this.devices;
    }
}

export const widthDetector = new WidthDetector();
