import type { AppDispatch } from 'shared/interfaces/store/store';
import {
    SITE_LOADER_HIDE,
    SITE_LOADER_TOGGLE
} from './siteLoader.types';

export const toggleSiteLoader =
    (show: boolean, label = '', source = null) =>
    (dispatch: AppDispatch) =>
        dispatch({
            type: SITE_LOADER_TOGGLE,
            payload: { show, label, source },
        });

export const hideSiteLoader = () => (dispatch: AppDispatch) =>
    dispatch({
        type: SITE_LOADER_HIDE,
    });
