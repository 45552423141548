import { SVGAttributes } from 'react';

export const EyeFilled = (props: SVGAttributes<SVGElement>) => (
    <svg {...props} width="67" height="67" viewBox="0 0 67 67" fill="none">
        <g clipPath="url(#clip0_9188_989)">
            <path d="M33.4996 19.4907C40.8639 19.4907 46.9975 23.7442 51.8075 32.0184L52.1851 32.682L52.2624 32.8571L52.3139 33.0025L52.3379 33.0988L52.3619 33.2424L52.3808 33.4175V33.6101L52.3568 33.8045C52.3455 33.8695 52.3306 33.9338 52.3122 33.9971L52.2452 34.1863L52.1834 34.3176L52.1559 34.3701C47.4112 42.8386 41.3412 47.2935 34.0369 47.5019L33.4996 47.5089C25.9533 47.5089 19.703 43.0453 14.8432 34.3684C14.6951 34.1038 14.6172 33.8045 14.6172 33.4998C14.6172 33.1952 14.6951 32.8958 14.8432 32.6312C19.703 23.9544 25.9533 19.4907 33.4996 19.4907ZM33.4996 28.2464C32.1338 28.2464 30.8239 28.7999 29.8581 29.7851C28.8923 30.7703 28.3497 32.1065 28.3497 33.4998C28.3497 34.8931 28.8923 36.2293 29.8581 37.2145C30.8239 38.1997 32.1338 38.7532 33.4996 38.7532C34.8654 38.7532 36.1753 38.1997 37.1411 37.2145C38.1069 36.2293 38.6495 34.8931 38.6495 33.4998C38.6495 32.1065 38.1069 30.7703 37.1411 29.7851C36.1753 28.7999 34.8654 28.2464 33.4996 28.2464Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_9188_989">
                <rect width="67" height="67" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
