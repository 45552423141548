import { SVGAttributes } from 'react';

const PostShares = (props: SVGAttributes<SVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
        <g clipPath="url(#clip0_9283_6774)">
            <path d="M14.0835 4.3335V8.66683C6.96062 9.7805 4.31187 16.0205 3.2502 21.6668C3.21012 21.89 9.08287 15.208 14.0835 15.1668V19.5002L22.7502 11.9168L14.0835 4.3335Z" stroke="#5079E4" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9283_6774">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default PostShares;
