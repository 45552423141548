import ReactDropdown, { Group, Option, ReactDropdownProps } from 'react-dropdown';

import { KeyboardArrowDownSmall } from '@components/svg/arrows/KeyboardArrowDownSmall';
import './Dropdown.sass';

export declare interface DropdownProps extends ReactDropdownProps {
    options: (Group | Option | string)[];
}

function Dropdown({
    options,
    value,
    disabled,
    placeholder,

    onChange,
    onFocus,

    baseClassName,
    className,
    controlClassName,
    placeholderClassName,
    menuClassName,
    arrowClassName,

    arrowClosed,
    arrowOpen,
}: DropdownProps) {

    return (
        <ReactDropdown
            options={options}
            value={value}

            onChange={onChange}
            onFocus={onFocus}

            placeholder={placeholder}
            disabled={disabled}

            baseClassName={baseClassName}
            className={`dropdown ${className ? className : ''}`}
            controlClassName={`dropdown-control ${controlClassName ? controlClassName : ''}`}
            placeholderClassName={`dropdown-placeholder ${placeholderClassName ? placeholderClassName : ''}`}
            menuClassName={`dropdown-menu ${menuClassName ? menuClassName : ''}`}
            arrowClassName={`dropdown-arrow ${arrowClassName ? arrowClassName : ''}`}

            arrowClosed={arrowClosed ? arrowClosed : <KeyboardArrowDownSmall />}
            arrowOpen={arrowOpen ? arrowOpen : <KeyboardArrowDownSmall style={{ transform: 'rotate(180deg)' }} />}
        />
    );
}

export default Dropdown;
