import { ThemeColors } from './colors';

export const MaximumDataSeriesBeforeSwitchingToCanvas = 1000;

export const baseChartGrid = {
    left: 40,
    right: 30
    // for mobile
    // left: 33,
    // right: 20
};

// conversion
export const ConversionColors = {
    subscription: ThemeColors.themeStatsColor1,
    newsletter: ThemeColors.themeStatsColor2,
    share: ThemeColors.themeStatsColor3,
};

// traffic sources colors
export const TrafficSourcesColorsArray = [
    ThemeColors.themeStatsColor1,
    ThemeColors.themeStatsColor4,
    ThemeColors.themeStatsColor3,
    ThemeColors.themeStatsColor2,
    ThemeColors.themeStatsColor5,
    ThemeColors.themeStatsColor6,
];
export const TrafficSourcesColors = {
    socials: ThemeColors.themeStatsColor1,
    referrers: ThemeColors.themeStatsColor4,
    organic: ThemeColors.themeStatsColor3,
    direct: ThemeColors.themeStatsColor2,
    internals: ThemeColors.themeStatsColor5,
    others: ThemeColors.themeStatsColor6,
};

// chart label style
export const ChartLabelStyle = {
    axisLabel: {
        margin: 15,
        color: ThemeColors.inputText,
        fontFamily: 'Poppins, Cairo, Roboto, sans-serif',
        fontWeight: 300,
        fontSize: '0.7rem',
        opacity: 1,
    },
};

// default chart symbol
export const DefaultLineChartSymbol = {
    symbol: 'circle',
    symbolSize: 6,
    showSymbol: true,
    color: ThemeColors.themeStatsColor2
};

// default line serie options
export const DefaultLineSerieOptions = {
    smooth: true,
    type: 'line',
    lineStyle: { color: ThemeColors.themeStatsColor2, width: 1.5 },
    ...DefaultLineChartSymbol,
    animationEasingUpdate: 'linear',
    animationDelay (idx) {
        return idx / 2;
    },
};

// default chart animation
export const DefaultChartAnimation = {
    global: {
        animationEasing (k) {
            let s;
            let a   = 0.1;
            const p = 0.4;

            if (k === 0) {
                return 0;
            }

            if (k === 1) {
                return 1;
            }

            if (!a || a < 1) {
                a = 1;
                s = p / 4;
            } else {
                s = (p * Math.asin(1 / a)) / (2 * Math.PI);
            }

            return (
                a *
                Math.pow(2, -10 * k) *
                Math.sin(((k - s) * (2 * Math.PI)) / p) +
                1
            );
        },
        animationDelayUpdate(idx) {
            return idx * 5 + 100;
        },
    },
    serie: {
        delay: (elementDelay = 0) => ({
            animationDelay: (idx) => idx * 10 + elementDelay,
            animationDelayUpdate: (idx) => idx * 5 + elementDelay,
        }),
    },
};

// default tooltip options
export const DefaultTooltipOptions = {
    extraCssText: 'border-radius: 5px;',
    textStyle: {
        color: ThemeColors.black,
        overflow: 'breakAll'
    },
    confine: true,
};

// default tooltip options
export const DefaultTooltipLinePointerOptions: echarts.TooltipComponentOption = {
    ...DefaultTooltipOptions,
    trigger: 'axis',
    axisPointer: {
        animation: true,
        type: 'line',
        axis: 'x',
        label: {
            show: true,
            backgroundColor: '#6a7985',
            margin: 10,
            fontSize: '0.75rem'
        },
    },
};
