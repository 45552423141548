/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { isValidElement } from 'react';
import TdItem from './TdItem';

interface Props {
    onChange?;
    showArrow?: boolean;
    bodyItems: (Record<string, any> | string | [] | JSX.Element)[][];
    itemsNotBold?: boolean;
    goToLinkWhenClickedOnTdFirstChild?: boolean;
    tableWrapperId: string;
}

const dropdownRevealItems = (tableWrapperId: string, dropdownConfig: { status: boolean; index: number; itemsIndexes: number[] }) => {
    dropdownConfig.itemsIndexes.forEach((index) => {
        const item = document.querySelector(`#${tableWrapperId} #tr-${index}`);

        if (!item) {return;}

        if (item.classList.contains('cbt-hide-tr')) {
            item.classList.remove('cbt-hide-tr');

            const td: HTMLElement = item?.querySelector('td:first-child');

            if (!td) {return;}

            td.style.paddingLeft = '70px';
        } else {
            item.classList.add('cbt-hide-tr');
        }
    });
};

const TdItemWrapper: React.FunctionComponent<Props> = ({ tableWrapperId, bodyItems, showArrow = true, itemsNotBold, goToLinkWhenClickedOnTdFirstChild = false }) => {
    const dropdownConfig = {};
    let dropdownStarted  = { status: false, currentIndex: 0 };

    return (
        <>
            {
                bodyItems.map((item, itemIndex) => {
                    const dropdownCondition = dropdownStarted &&
                                            dropdownConfig?.[dropdownStarted?.currentIndex]?.status &&
                                            (!(item[0] as { isDropdown: boolean })?.isDropdown);
                    const trClass           = dropdownCondition
                        ? 'cbt-hide-tr'
                        : '';

                    if ((item[0] as { isDropdown: boolean })?.isDropdown){
                        (item[0] as { isDropdownEnded: boolean }).isDropdownEnded = true;
                    }

                    if (dropdownCondition) {
                        dropdownConfig[dropdownStarted.currentIndex].itemsIndexes.push(itemIndex);
                    }

                    return (
                        <tr key={itemIndex} id={`tr-${itemIndex}`} className={trClass}>
                            {
                                item.map((dataItem: [] | string | Record<string, any>, index) => {
                                    let props: {
                                        highlight: string;
                                        showLogo?: boolean;
                                        pageUrl?: string;
                                        itemsNotBold?: boolean;
                                    } | {
                                        image: string;
                                        title: string;
                                        pageUrl?: string;
                                        goToLinkWhenClickedOnTdFirstChild?: boolean;
                                        isDropdownItem?: boolean;
                                        itemOnClick?: (value?) => void;
                                    };

                                    if (isValidElement(dataItem)) {
                                        return (
                                            <td>
                                                {dataItem}
                                            </td>
                                        );
                                    }

                                    if (typeof dataItem === 'string') {
                                        props = {
                                            highlight: dataItem,
                                            showLogo: false,
                                            itemsNotBold,
                                        };

                                    } else if (Array.isArray(dataItem)){
                                        props = {
                                            highlight: dataItem[0],
                                            showLogo: showArrow,
                                            itemsNotBold,
                                            pageUrl: dataItem[1],
                                            // @ts-ignore
                                            replaceTitleWithLinkTag: dataItem[2],
                                        };
                                    } else {
                                        let itemOnClick;

                                        if (dataItem?.isDropdown) {

                                            const config = {
                                                status: true,
                                                index: dataItem?.dropdownIndex,
                                                itemsIndexes: [],
                                            };

                                            dropdownConfig[dataItem?.dropdownIndex] = config;
                                            itemOnClick                             = () => {
                                                dropdownRevealItems(tableWrapperId, config);
                                            };
                                            dropdownStarted                         = {
                                                status: true,
                                                currentIndex: dataItem?.dropdownIndex
                                            };
                                        }

                                        if (dataItem?.isDropdownEnded) {
                                            dropdownStarted = {
                                                status: false,
                                                currentIndex: dataItem?.dropdownIndex
                                            };
                                        }

                                        props = {
                                            ...(dataItem as { image: string; title: string }),
                                            goToLinkWhenClickedOnTdFirstChild,
                                            itemOnClick,
                                        };
                                    }

                                    return (
                                        <TdItem
                                            key={'td-item-' + index}
                                            {...props}
                                        />
                                    );
                                })
                            }
                        </tr>
                    );
                })
            }
        </>
    );
};

export default TdItemWrapper;
