import { CSSProperties } from 'react';

declare interface OutlineDotsVerticalProps {
    fill?: string;
    onClick?: () => void;
    style?: CSSProperties;
    className?: string;
    id?: string;
}

const OutlineDotsVertical = ({ fill, style, className, id, onClick }: OutlineDotsVerticalProps) => (
    <svg
        style={style}
        className={className}
        id={id}
        onClick={onClick}
        width="5"
        height="17"
        viewBox="0 0 5 17"
        fill="none"
    >
        <circle cx="2.00359" cy="2.00347" r="2.00347" fill={fill || '#313131'}/>
        <circle cx="2.00347" cy="7.61334" r="2.00347" fill={fill || '#313131'}/>
        <circle cx="2.00347" cy="14.0243" r="2.00347" fill={fill || '#313131'}/>
    </svg>

);

export default OutlineDotsVertical;
