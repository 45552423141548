import { useGSAP } from '@gsap/react';
import { useState } from 'react';

import { gsap } from '@services/gsap';

export default function useWidgetAnimation<T = HTMLElement>(gridWrapperRef: React.MutableRefObject<T>, startImmediately = true, hideWidgetInitially = true): [boolean, (value: boolean) => void] {
    const [animate, toggleAnimation]        = useState<boolean>(startImmediately ? startImmediately : false);
    const selector: gsap.utils.SelectorFunc = gsap.utils.selector(gridWrapperRef);

    useGSAP(() => {
        const widgetSelector = '.widget-item';

        if (!animate) {
            hideWidgetInitially && gsap.set(selector(widgetSelector), { opacity: 0 });

            return;
        } else {
            gsap.set(selector(widgetSelector), { opacity: '' });
        }

        gsap.from(selector(widgetSelector), {
            delay: 0.3,
            opacity: 0,
            top: -30,
            // left: -30,
            duration: 0.5,
            stagger: 0.05,
            ease: 'Expo.easeInOut',
        });

        return () => {
            toggleAnimation(false);
        };
    }, { dependencies: [animate, hideWidgetInitially], scope: gridWrapperRef });

    return [animate, toggleAnimation];
}
