import { SVGAttributes } from 'react';

const PostPaid = (props: SVGAttributes<SVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_10615_991)">
            <path
                d="M9.95573 6.33333C9.87279 6.09802 9.72133 5.8929 9.52084 5.74439C9.32035 5.59588 9.08 5.51076 8.83073 5.5H7.16406C6.83254 5.5 6.5146 5.6317 6.28018 5.86612C6.04576 6.10054 5.91406 6.41848 5.91406 6.75C5.91406 7.08152 6.04576 7.39946 6.28018 7.63388C6.5146 7.8683 6.83254 8 7.16406 8H8.83073C9.16225 8 9.48019 8.1317 9.71461 8.36612C9.94903 8.60054 10.0807 8.91848 10.0807 9.25C10.0807 9.58152 9.94903 9.89946 9.71461 10.1339C9.48019 10.3683 9.16225 10.5 8.83073 10.5H7.16406C6.91479 10.4892 6.67444 10.4041 6.47395 10.2556C6.27346 10.1071 6.122 9.90198 6.03906 9.66667"
                stroke="#767CA8"
                strokeWidth={0.833333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 4.25V5.5M8 10.5V11.75"
                stroke="#767CA8"
                strokeWidth={0.833333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <circle cx={8} cy={8} r={7.5} stroke="#767CA8" />
        <defs>
            <clipPath id="clip0_10615_991">
                <rect width={10} height={10} fill="white" transform="translate(3 3)" />
            </clipPath>
        </defs>
    </svg>
);

export default PostPaid;
