const hours      = Array.from({ length: 24 }, (_, i) =>
    `${String(i).padStart(2, '0')}:00`
);
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reverse();

const formatHeatmapData = (data) => {
    const dataMatrix = Array(7)
        .fill(null)
        .map(() => Array(24).fill(null));
    const chartData  = data.series[0];

    chartData?.data?.forEach(({ value }) => {
        const [hour, day, heatmapValue] = value;

        if (day < 7 && hour < 24) {
            dataMatrix[day][hour] = heatmapValue;
        }
    });

    return dataMatrix;
};


function convertToXlSXPie(series = []){
    const rows   = [];
    const header = ['Category'];

    series.forEach(serie => {
        const { data } = serie;

        data.forEach(d => {
            header.push(d.name);
        });

    });
    rows.push(header);

    const body = ['Value'];

    series.forEach(serie => {
        const { data } = serie;

        data.forEach(d => {
            body.push(d.value);
        });
    });
    rows.push(body);

    return rows;
}

function convertToXLSXHeatMapChart(data){
    const dataMatrix    = formatHeatmapData(data);
    const worksheetData = [['Week Days', ...hours]];

    daysOfWeek.forEach((day, i) => {
        worksheetData.push([day, ...dataMatrix[i]]);
    });

    return worksheetData;
}

function convertToXLSXCommunChart(data,showFirstSeries){
    const rows          = [];
    let { series = [] } = data;

    if (showFirstSeries){
        series = [data.series[0]];
    }

    const header     = ['Date'];
    const seriesList = series.filter(series => series.type !== 'pie');

    seriesList.forEach(series => {
        header.push(series.name);
    });

    rows.push(header);

    let categories = data.xAxis ? data.xAxis[0].data : [];

    if (!categories){
        categories = series[0].data.map((el,i)=> i);
    }

    if (categories.length === 0){
        return [];
    }

    for (let i = 0; i < categories.length; i++) {
        const row = [categories[i]];

        seriesList.forEach(series => {
            if (typeof series.data[i] == 'object' && series.data[i]){
                row.push(series.data[i].value);
            } else {
                row.push(series.data[i]);
            }
        });

        rows.push(row);
    }

    return rows;
}


function convertToXlsxGaugeChart(series){
    const headers       = ['value'];
    const rows          = [];
    const { value = 0 } = series[0].data[0];

    rows.push(headers);
    rows.push([value]);

    return rows;
}


function convertToXLSX(data,showFirstSeries = false){
    if (!data) {return [];}

    const { series = [] } = data;

    const isPie     = series.find(el=> el.type === 'pie');
    const isHeatMap = series.find(el=> el.type === 'heatmap');
    const isGauge   = series.find(el=> el.type === 'gauge');
    let rows        = [];

    if (isGauge){
        rows = convertToXlsxGaugeChart(series);

        return rows;
    }

    if (isPie){
        rows = convertToXlSXPie(series);

        return rows;
    }

    if (isHeatMap){
        rows = convertToXLSXHeatMapChart(data);

        return rows;
    }

    rows = convertToXLSXCommunChart(data,showFirstSeries);

    return rows;
}


export default convertToXLSX;
