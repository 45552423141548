import OutlineDotsVertical from 'components/svg/OutlineDotsVertical';
import React, { useEffect, useRef } from 'react';

import { OverviewFeatureWrapperProps } from '../OverviewFeatureWrapper';

declare interface OverviewFeatureWrapperHeaderProps {
    headerPadding: string;
    title: OverviewFeatureWrapperProps['title'];
    besideTitleText?: string;
    centerTitle?: boolean;
    showTitle?: boolean;
    menuOptionStyle: React.CSSProperties;
    menuStatus: boolean;
    setShowMenu: (value: boolean) => void;
    optionMenu?: OverviewFeatureWrapperProps['optionMenu'];
    disableOptions?: OverviewFeatureWrapperProps['disableOptions'];
    loaderColor?: OverviewFeatureWrapperProps['loaderColor'];
    noPadding?: boolean;
}

const OverviewFeatureWrapperHeader = ({
    headerPadding,
    title,
    besideTitleText,
    centerTitle,
    showTitle,
    loaderColor,
    disableOptions,
    menuOptionStyle,
    menuStatus,
    setShowMenu,
    optionMenu,
    noPadding,
}: OverviewFeatureWrapperHeaderProps) => {
    const options = useRef<HTMLDivElement>(null);
    const menu    = useRef<HTMLDivElement>(null);

    const clickHandler = (e) => {
        if (
            !options?.current?.contains(e.target) &&
            !document?.querySelector('.widget-menu')?.contains(e.target) &&
            !menu?.current?.contains(e.target)
        ) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        if (options.current && menuStatus) {
            document.addEventListener('click', clickHandler);
        }

        return () => {
            if (document) {
                document.removeEventListener('click', clickHandler);
            }
        };
    }, [menuStatus]);

    return (
        <div
            data-testid="wg-header"
            className={'widget-header' + (noPadding ? ' widget-no-padding' : '')}
            style={{
                padding: headerPadding,
            }}
        >
            <h2 className="widget-title" style={{ margin: centerTitle ? '0 auto' : '' }}>
                {showTitle !== false && <span style={{ margin: centerTitle ? 0 : '' }}  data-testid="wg-title">{title}</span>}
                {besideTitleText && <p>{besideTitleText}</p>}
            </h2>
            {!disableOptions && optionMenu && (
                <div
                    ref={options}
                    data-testid="wg-options"
                    className="widget-options _pointer"
                >
                    <OutlineDotsVertical
                        className="menu _transition"
                        style={menuOptionStyle}
                        onClick={() => setShowMenu(true)}
                    />

                    <div className="widget-menu" ref={menu}>
                        {optionMenu}
                    </div>
                </div>
            )}
        </div>
    );
};

OverviewFeatureWrapperHeader.defaultProps = {
    optionMenu: null,
    disableOptions: false,
    loaderColor: '',
};

export default OverviewFeatureWrapperHeader;
