import { SVGAttributes } from 'react';

const PostUnPaid = (props: SVGAttributes<SVGElement>) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_10615_990)">
            <path
                d="M8 8.16699C8 7.50395 7.73661 6.86807 7.26777 6.39923C6.79893 5.93038 6.16304 5.66699 5.5 5.66699H4.25V6.50033C4.25 7.16337 4.51339 7.79925 4.98223 8.26809C5.45107 8.73693 6.08696 9.00033 6.75 9.00033H8"
                stroke="#767CA8"
                strokeWidth={0.833333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 9.83301C8 9.16997 8.26339 8.53408 8.73223 8.06524C9.20107 7.5964 9.83696 7.33301 10.5 7.33301H11.75V7.74967C11.75 8.41272 11.4866 9.0486 11.0178 9.51744C10.5489 9.98628 9.91304 10.2497 9.25 10.2497H8"
                stroke="#767CA8"
                strokeWidth={0.833333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 12.3337V8.16699"
                stroke="#767CA8"
                strokeWidth={0.833333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <circle cx={8} cy={8} r={7.5} stroke="#767CA8" />
        <defs>
            <clipPath id="clip0_10615_990">
                <rect width={10} height={10} fill="white" transform="translate(3 4)" />
            </clipPath>
        </defs>
    </svg>
);

export default PostUnPaid;
