import { SVGAttributes } from 'react';

const PostWatchTime = (props: SVGAttributes<SVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
        <g clipPath="url(#clip0_10409_1080)">
            <path d="M5.42188 14.0833C5.42187 15.0792 5.61802 16.0653 5.99912 16.9853C6.38022 17.9054 6.9388 18.7414 7.64298 19.4456C8.34716 20.1497 9.18314 20.7083 10.1032 21.0894C11.0232 21.4705 12.0094 21.6667 13.0052 21.6667C14.0011 21.6667 14.9872 21.4705 15.9072 21.0894C16.8273 20.7083 17.6633 20.1497 18.3674 19.4456C19.0716 18.7414 19.6302 17.9054 20.0113 16.9853C20.3924 16.0653 20.5885 15.0792 20.5885 14.0833C20.5885 12.0721 19.7896 10.1433 18.3674 8.72111C16.9453 7.29896 15.0164 6.5 13.0052 6.5C10.994 6.5 9.06513 7.29896 7.64298 8.72111C6.22083 10.1433 5.42188 12.0721 5.42188 14.0833Z" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.7083 11.375L13 14.0833" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.4219 8.66634L19.5052 7.58301" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.1615 3.25H10.8281" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_10409_1080">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default PostWatchTime;
