import classNames from 'classnames';

import { Dropdown, DropdownProps } from '../dropdown';
import './IDropdown.scss';

const IDropdown = (props: DropdownProps) => (
    <Dropdown
        {...props}
        className={classNames('i-dropdown', { [props?.className]: !!props?.className })}
    />
);

export default IDropdown;
