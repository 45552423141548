/* eslint-disable import/no-unresolved */
import classNames from 'classnames';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useOutletContext } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperDTO } from 'swiper/types';

import 'swiper/css';

import { KeyboardArrowRightMedium, PostClicks, PostComments, PostReactions, PostShares, PostTotalViews, PostViewsCompleted, PostWatchTime } from '@components/svg';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import { SinglesLayoutContextType } from '@layouts/social-media/SocialMediaSinglesLayout';
import { capitalizeFirstLetter } from '@services/conversion/conversion';
import DateInstance from '@services/date/date';
import { neitherNullNorUndefined } from '@services/validation/validation';
import { SocialMediaSingleAllPosts } from '@shared/interfaces/social-media/socialMediaSingle';
import './FacebookPostPopup.scss';
import FacebookPostPopupSlideItem from './slide-item/FacebookPostPopupSlideItem';

interface Props {
    networkType: SocialMediaTypes;
    posts: SocialMediaSingleAllPosts;
    className?: string;
    onChange: (id: string) => void;
}

const FacebookPostPopup = ({  networkType, posts, className, onChange }: Props) => {
    const [container, setContainer] = React.useState<Element>(document.getElementById('fpp-container'));

    React.useEffect(() => {
        setContainer(document.getElementById('fpp-container'));
    }, []);

    const [swiper, setSwiper]     = useState<SwiperDTO>(null);

    const handleTransitionToRight = () => swiper.slideNext();
    const handleTransitionToLeft  = () => swiper.slidePrev();

    const {
        currentProfile,
    } = useOutletContext<SinglesLayoutContextType>();

    return container ? createPortal(
        <div className={classNames('fpp-wrapper', { [className]: !!className })}>
            <div className="fpp-head">
                <Link className="fpp-back" to="../all-posts" state={{ networkType }}>
                    <KeyboardArrowRightMedium />
                    <p>Back to all posts</p>
                </Link>
            </div>
            <div className="fpp-container">
                {!swiper?.isBeginning && (
                    <div className={classNames('fpp-swiper-control --leftControl')}>
                        <KeyboardArrowRightMedium onClick={handleTransitionToLeft} />
                    </div>
                )}
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    onSwiper={setSwiper}
                    className="fpp-container"
                    onSlideChangeTransitionEnd={(swiper) => {
                        onChange?.(String(swiper.activeIndex));

                        // swiper.slideTo(5, );
                    }}
                >

                    {posts?.map((post, idx) => (
                        <SwiperSlide className="fpp-item-wrapper" key={`${ post.id }-${idx}`}>
                            <FacebookPostPopupSlideItem
                                id={post.reference}
                                details={{
                                    networkType,
                                    profileThumb: currentProfile?.thumbnail,
                                    profileUsername: currentProfile?.profile,
                                    profileUrl: currentProfile?.url,
                                    groupUrl: null,
                                    sharedIn: capitalizeFirstLetter(post.type.toLowerCase()),
                                    publishDate: post?.date && DateInstance.formatDate({ date: DateInstance.newDate(post.date), format: 'MMMM dd yyyy' }),
                                    postThumb: post?.image,
                                    postContent: post?.message,
                                }}
                                highlights={{
                                    impressions: {
                                        value: (post?.impressions?.organic || 0) + (post?.impressions?.paid || 0),
                                        paid: post?.impressions?.paid,
                                        unpaid: post?.impressions?.organic
                                    },
                                    reach: {
                                        value: (post?.reach?.organic || 0) + (post?.reach?.paid || 0),
                                        paid: post?.reach?.paid,
                                        unpaid: post?.reach?.organic
                                    },
                                    interactions: {
                                        value: post?.interactions
                                    }
                                }}
                                interactions={[
                                    {
                                        title: 'Interactions details',
                                        items: [
                                            neitherNullNorUndefined(post?.reactions) && {
                                                icon: <PostReactions />,
                                                title: 'Reactions',
                                                value: post.reactions,
                                            },
                                            neitherNullNorUndefined(post?.comments) && {
                                                icon: <PostComments />,
                                                title: 'Comments',
                                                value: post.comments,
                                            },
                                            neitherNullNorUndefined(post?.shares) && {
                                                icon: <PostShares />,
                                                title: 'Shares',
                                                value: post.shares,
                                            },
                                            neitherNullNorUndefined(post?.clicks) && {
                                                icon: <PostClicks />,
                                                title: 'Clicks',
                                                value: post.clicks,
                                            },
                                        ].filter(Boolean)
                                    },
                                    {
                                        title: 'Views and watch time',
                                        items: [
                                            {
                                                icon: <PostTotalViews />,
                                                title: 'Total views',
                                                value: 1645,
                                            },
                                            {
                                                icon: <PostViewsCompleted />,
                                                title: 'Views completed',
                                                value: 12,
                                            },
                                            {
                                                icon: <PostWatchTime />,
                                                title: 'Watch time',
                                                value: '02:09',
                                                formatNumber: false,
                                            },
                                        ]
                                    }
                                ]}
                            />
                        </SwiperSlide>
                    )) || null}

                </Swiper>
                {!swiper?.isEnd && (
                    <div className={classNames('fpp-swiper-control --rightControl')}>
                        <KeyboardArrowRightMedium onClick={handleTransitionToRight} />
                    </div>
                )}
            </div>
        </div>,
        container
    ) : null;
};

export default FacebookPostPopup;
