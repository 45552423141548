import { SortTriangle } from '@components/svg/SortTriangle';
import classNames from 'classnames';
import React from 'react';

declare interface ThItemProps {
    title: string;
    onClick?: (value: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => void;
    isActive?: boolean;
    isReversed?: boolean;
    isSortable?: boolean;
}

const ThItem = ({ title, onClick, isActive = false, isReversed = false, isSortable = true }: ThItemProps) => (
    <th
        className={`${isActive && isSortable ? 'active' : ''} ${
            !isSortable ? '--notSortable' : ''
        }`}
        onClick={(e) => {
            isSortable && onClick?.(e);
        }}
    >
        <div className={isReversed ? '--reversed' : ''}>
            <p>{title}</p>
            {
                isSortable && (
                    <div className='sort-polygons'>
                        <SortTriangle className={classNames({ active: isActive && isReversed })} style={{ rotate: '.5turn' }} />
                        <SortTriangle className={classNames({ active: isActive && !isReversed })} isActive={false} />
                    </div>
                )
            }
        </div>
    </th>
);

export default ThItem;
