import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { useState } from 'react';
import ThItem from './ThItem';

declare interface ThItemWrapperProps {
    items: ({ title: string; isSortable: boolean } | string)[];
    /**
     * index of the default selected item
    */
    defaultOrder?: number;
    reversedDefault?: boolean;
    onChange?: (title: string, reversed?: boolean) => void;
}

const ThItemWrapper = ({ items, defaultOrder = 0, reversedDefault = false, onChange }: ThItemWrapperProps) => {
    const [state, setState] = useState<{ [key: string]: { selected: boolean; reverse: boolean } }>(
        items.reduce((acc, item, index) => {
            let key;

            if (typeof item !== 'string') {
                key =  item.title;
            } else {
                key = item;
            }

            return { ...acc, [key]: { selected: index === defaultOrder, reverse: reversedDefault } };
        }, {})
    );

    const handleClick = (title) => {
        setState((prev) => {
            if (prev[title].selected){
                return ({
                    ...prev,
                    [title]: { selected: true, reverse: !prev[title].reverse }
                });
            }

            return {
                ...items.reduce((acc, item) => {
                    let key;

                    if (typeof item !== 'string') {
                        key =  item.title;
                    } else {
                        key = item;
                    }

                    return { ...acc, [key]: { selected: false, reverse: false } };
                }, {}),
                [title]: { selected: true, reverse: false }
            };
        });
    };

    useUpdateEffect(() => {
        const currentSelectedItem = Object.entries(state).find(([, item]) => item.selected);

        onChange?.(currentSelectedItem?.[0], currentSelectedItem?.[1]?.reverse || false);
    }, [state]);

    return (
        <>
            {
                items.map((item, index) => {
                    let key;
                    let isSortable = true;

                    if (typeof item !== 'string') {
                        key        =  item.title;
                        isSortable = item.isSortable ?? true;
                    } else {
                        key = item;
                    }

                    return (
                        <ThItem
                            key={index}
                            title={key}
                            isActive={state[key].selected}
                            isReversed={state[key].reverse}
                            onClick={() => handleClick(key)}
                            isSortable={isSortable}
                        />
                    );
                })
            }
        </>
    );
};

export default ThItemWrapper;
