const FacebookPostPopupContainer = () => (
    <div
        id="fpp-container"
        style={{ position: 'relative', width: '100%', height: '100%' }}
    >

    </div>
);

export default FacebookPostPopupContainer;
