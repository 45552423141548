import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

export const ArrowRightShort = ({ ...rest }: BaseSvgComponentProps) => (
    <svg
        {...rest}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <path d="M2.81251 9.56248L14.0341 9.56248L9.73716 13.6552C9.51216 13.8695 9.50344 14.2256 9.71775 14.4506C9.93178 14.6753 10.2878 14.6843 10.5131 14.47L15.4204 9.79564C15.6327 9.58301 15.75 9.30064 15.75 8.99998C15.75 8.69961 15.6327 8.41695 15.4105 8.19505L10.5128 3.53023C10.404 3.42645 10.2645 3.37498 10.125 3.37498C9.9765 3.37498 9.828 3.43348 9.71747 3.54964C9.50316 3.77464 9.51188 4.13042 9.73688 4.34473L14.0518 8.43748L2.81251 8.43748C2.50201 8.43748 2.25001 8.68948 2.25001 8.99998C2.25001 9.31048 2.50201 9.56248 2.81251 9.56248Z" fill="#FF5E5F"/>
    </svg>
);
