import classNames from 'classnames';
import { ReactNode } from 'react';

import { PostPaid, PostUnPaid } from '@components/svg';
import { symbolizeNumber } from '@services/conversion/conversion';
import { neitherNullNorUndefined } from '@services/validation/validation';

import './FPPHighlightItem.scss';

interface Props {
    icon: ReactNode;
    title: string;
    value: number;
    paid?: number;
    unpaid?: number;
    className?: string;
    theme?: 'red' | 'yellow' | 'blue';
}

const FPPHighlightItem = ({ icon, title, value, paid, unpaid, className, theme = 'red' }: Props) => (
    <div className={classNames('fpphi-container', { [className]: !!className, [theme]: theme, })}>
        <header className="fpphi-head">
            <div>
                {icon}
            </div>
            <h5>{title}</h5>
        </header>

        <h2>{symbolizeNumber(Number(value || 0), 1, {
            convertToComma: true,
            shouldRound: true,
            ignoreFirstSixDigits: true
        })}</h2>

        {neitherNullNorUndefined(paid) && neitherNullNorUndefined(unpaid) && (
            <div className="fpphi-tag">
                <PostUnPaid />
                <p>{symbolizeNumber(Number(unpaid || 0), 1, {
                    convertToComma: true,
                    shouldRound: true,
                    ignoreFirstSixDigits: true
                })}</p>
                <PostPaid />
                <p>{symbolizeNumber(Number(paid || 0), 1, {
                    convertToComma: true,
                    shouldRound: true,
                    ignoreFirstSixDigits: true
                })}</p>
            </div>
        )}
    </div>
);

export default FPPHighlightItem;
