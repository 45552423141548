/* eslint-disable @typescript-eslint/naming-convention */
import { useLayoutEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import type { SiteLoaderState } from 'store/site-loader/siteLoader.reducer';

import { DefinedRoutes } from '@routes/definedRoutes';
import { DataStatus } from '@shared/enum/dataStatus';

import { setPageDownloadPageLoad } from '@store/page-download/pageDownload.slice';
import { useAppDispatch, useAppSelector } from '../redux/useRedux';
import useSiteLoader from '../site-loader/useSiteLoader';
import useWidgetAnimation from '../widget-animation/useWidgetAnimation';


const isFromSinglePage = (pathname) => pathname.split('/')[2] === DefinedRoutes.pages.name && pathname.split('/').length > 3;
// const isFromPagesPage  = (pathname) => pathname.split('/')[2] === DefinedRoutes.pages.name && pathname.split('/').length === 3;

export default function useLoadingData<T = HTMLElement>(gridWrapper: React.MutableRefObject<T>, loaderLabel = 'Loading...', animateWidgets = true): [boolean, SiteLoaderState] {
    const { pathname } = useLocation();
    const dispatch     = useAppDispatch();

    // set properties that invoke the loading state of a specific page
    const StatePropertiesByPageMapper = {
        [DefinedRoutes.overview.path]: [
            'audience',
            'live',
            'impact',
            'topContent',
            'authors',
            'topCategories',
            'languageByPageView',
            'conversionPerDay',
            'depthRate',
            'usersPerHour',
            'trafficSourceDistribution',
            'trafficSourcePerDay',
            'devices',
            'countries',
            'browsers',
        ],
        [DefinedRoutes.realTime.path]: [
            'live',
            'liveActivePages',
            'liveCountries',
            'liveCirculations',
        ],
        [DefinedRoutes.mentions.path]: [
            'mentions',
            'allMentions'
        ],

        // content pages
        [DefinedRoutes.pages.overview.path]: [
            'audience',
            'authors',
            'topContent',
            'languageByPageView',
            'topCategories',
            'depthRate'
        ],
        [DefinedRoutes.pages.articlesAndPages.path]: [
            'allContent'
        ],
        [DefinedRoutes.pages.categories.path]: [
            'topCategories',
            'categoriesPerDay',
            'categoriesTable',
        ],
        [DefinedRoutes.pages.authors.path]: [
            'allAuthors',
        ],
        [DefinedRoutes.pages.page.path]: [ // single page
            'audience',
            'impact',
            'meta',
            'depthRate',
            'funnel',
            'trafficSourceDistribution',
            'trafficSourcePerDay',
            'devices',
            'countries',
            'browsers',
        ],
        [DefinedRoutes.pages.author.path]: [ // single author
            'authorsHighlights',
            'authorsArticles',
        ],
        [DefinedRoutes.pages.category.path]: [ // single category
            'audience',
            'allContent',
        ],

        // audience pages
        [DefinedRoutes.audience.overview.path]: [
            'audience',
            'trafficSourceDistribution',
            'trafficSourcePerDay',
            'devices',
            'countries',
            'browsers',
        ],
        [DefinedRoutes.audience.countries.path]: [
            'countries',
            'countriesTable',
        ],
        [DefinedRoutes.audience.country.path]: [ // single country
            'audience',
            'impact',
            'conversion',
            'allContent',
            'browsers',
            'devices',
            'browsersTable',
            'devicesPerDay',
            'devicesTable',
            'browsersPerDay',
            'depthRate',
        ],
        [DefinedRoutes.audience.devices.path]: [
            'devices',
            'devicesPerDay',
            'devicesTable',
        ],
        [DefinedRoutes.audience.browsers.path]: [
            'browsers',
            'browsersPerDay',
            'browsersTable',
        ],
        [DefinedRoutes.audience.acquisition.path]: [
            'trafficSourceDistribution',
            'trafficSourcePerDay',
            'trafficSourceTable'
        ],

        // social media pages
        [DefinedRoutes.socialMedia.path]: [
            'socialMediaOverview'
        ],
        [DefinedRoutes.socialMedia.details.path]: [
            'socialMediaSingle'
        ]
    };

    // load all data store loaders
    const state    = useAppSelector((state) => ({
        live: state.liveReducer.liveStatus === DataStatus.LOADING,
        liveActivePages: state.liveReducer.activePagesStatus === DataStatus.LOADING,
        liveCountries: state.liveReducer.countriesStatus === DataStatus.LOADING,
        liveCirculations: state.liveReducer.circulationsStatus === DataStatus.LOADING,

        audience: state.viewsReducer.audienceIsLoading,
        impact: state.impactScoreReducer.status === DataStatus.LOADING,
        conversion: state.conversionsReducer.isLoading,
        depthRate: state.depthRateReducer.isLoading,

        topContent: state.topContentReducer.isLoading,
        allContent: state.allContentReducer.isLoading,

        authors: state.authorReducer.isLoading,
        authorsHighlights: state.authorReducer.highlightsIsLoading,
        authorsArticles: state.authorReducer.articlesIsLoading,
        allAuthors: state.authorReducer.allAuthorsIsLoading,

        countries: state.countriesReducer.isLoading,
        countriesTable: state.countriesReducer.countriesTableIsLoading,

        gender: state.genderAgeReducer.genderIsLoading,
        genderPerDay: state.genderAgeReducer.genderPerDayIsLoading,
        age: state.genderAgeReducer.ageIsLoading,
        agePerDay: state.genderAgeReducer.agePerDayIsLoading,

        browsers: state.browsersReducer.isLoading,
        browsersPerDay: state.browsersReducer.browsersPerDayIsLoading,
        browsersTable: state.browsersReducer.browsersTableIsLoading,

        devices: state.devicesReducer.isLoading,
        devicesPerDay: state.devicesReducer.devicesPerDayIsLoading,
        devicesTable: state.devicesReducer.devicesTableIsLoading,

        languageByPageView: state.languagesReducer.languagesByPageViewIsLoading,
        languagesTable: state.languagesReducer.languagesTableIsLoading,
        languagesPerDay: state.languagesReducer.languagesPerDayIsLoading,

        topCategories: state.categoriesReducer.topCategoriesStatus === DataStatus.LOADING,
        categoriesPerDay: state.categoriesReducer.categoriesPerDayStatus === DataStatus.LOADING,
        categoriesTable: state.categoriesReducer.categoriesTableStatus === DataStatus.LOADING,

        trafficSourceDistribution: state.visitorBehaviorReducer.percentageIsLoading,
        trafficSourcePerDay: state.viewsReducer.trafficSourceIsLoading,
        trafficSourceTable: state.acquisitionReducer.isLoading,
        funnel: state.visitorBehaviorReducer.funnelIsLoading,

        meta: state.singleMetaReducer.isLoading,
        search: state.singleSearchReducer.isLoading,
        usersPerHour: state.visitorsPerHourReducer.isLoading,

        mentions: state.overviewMentionsReducer.mentionsStatus === DataStatus.LOADING,
        allMentions: state.overviewMentionsReducer.allMentionsStatus === DataStatus.LOADING,

        socialMediaOverview: state.socialMediaOverviewReducer.status === DataStatus.LOADING,
        socialMediaSingle: state.socialMediaSingleReducer.status === DataStatus.LOADING,
    }));

    // loading will becomes false when all state properties are false
    const isLoading = useMemo(() => {
        const filterByCurrentPage = Object
            .entries(state)
            .filter(([key]) => {
                let path = pathname;

                if (isFromSinglePage(pathname)){
                    ({ path } = DefinedRoutes.pages.page);
                }

                return StatePropertiesByPageMapper?.[path] && StatePropertiesByPageMapper[path].includes(key);
            });

        return filterByCurrentPage.reduce((previous, [, value]) => previous || value, false);
    }, [state]);

    const loader              = useSiteLoader();
    const [, toggleAnimation] = useWidgetAnimation<T>(gridWrapper, false, false);

    // show/hide loader and start widget animation
    useLayoutEffect(() => {
        // dispatch loader
        if (isLoading) {
            loader.dispatchToggle(true, loaderLabel);
            dispatch(setPageDownloadPageLoad(false));

            return;
        } else {
            loader.dispatchToggle(false);
            dispatch(setPageDownloadPageLoad(true));
        }

        // handle grid widget animation only if data is loaded
        animateWidgets && toggleAnimation(true);
    }, [isLoading]);

    return [isLoading, loader.state];
}


