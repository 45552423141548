/* eslint-disable @typescript-eslint/naming-convention */
import { gsap } from 'gsap';
import React, { LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import LoaderWrapper from '@components/widgets/loader/loader-wrapper/LoaderWrapper';
import { useGSAP } from '@gsap/react';
import OverviewFeatureWrapperFooter from 'components/wrappers/overview-feature-wrapper/footer/OverviewFeatureWrapperFooter';
import OverviewFeatureWrapperHeader from 'components/wrappers/overview-feature-wrapper/header/OverviewFeatureWrapperHeader';
import { mapStateToProps } from 'errors/error-tracker/ErrorTracker';
import './OverviewFeatureWrapper.sass';

export declare interface OverviewFeatureWrapperProps {
    children: React.ReactNode;
    bodyRef?: (node?: Element) => void | LegacyRef<HTMLElement>;
    isLoading?: boolean;
    title: string | React.ReactNode;
    besideTitleText?: string;
    centerTitle?: boolean;
    showTitle?: boolean;
    id?: string;
    errorWrapperTitler?: string;
    updatePeriod?: (period: string) => void;
    loaderColor?: string;
    disableOptions?: boolean;
    disablePeriod?: boolean;
    disableOverview?: boolean;
    seeAllRoute?: string;
    seeAllRouteText?: string;
    overviewStyle?: React.CSSProperties;
    optionColor?: string;
    optionMenu?: React.ReactNode;
    bodyHeight?: string;
    menuType?: string;
    menuStatus?: boolean;
    manualBodyPadding?: string;
    errorTracker?: { label: string; error: string }[];

    hideHeader?: boolean;
    hideFooter?: boolean;

    noHeaderPadding?: boolean;
    noBodyPadding?: boolean;
    noFooterPadding?: boolean;
    containerPositionUnset?: boolean;

    scaleDownErrorWrapperSize?: boolean;
    disableErrorChecking?: boolean;
    setContainer?: (elem) => void;
}

const titleMapper = {
    'session by country': 'session per country',
    'session by device': 'session per device',
    'session by language': 'session per language',
};

function OverviewFeatureWrapper ({
    children,
    bodyRef,
    id,
    title,
    besideTitleText,
    centerTitle,
    showTitle,
    errorWrapperTitler,
    updatePeriod,
    isLoading,
    loaderColor,
    disableOptions,
    disablePeriod,
    disableOverview,
    overviewStyle,
    optionColor,
    optionMenu,
    bodyHeight,
    manualBodyPadding,
    errorTracker,
    menuStatus,
    seeAllRoute,
    seeAllRouteText,
    hideHeader = false,
    hideFooter = false,
    noHeaderPadding = false,
    noBodyPadding = false,
    noFooterPadding = false,
    containerPositionUnset = false,
    scaleDownErrorWrapperSize = false,
    disableErrorChecking = false,
    setContainer
}: OverviewFeatureWrapperProps) {
    const [showMenu, setShowMenu]           = useState<boolean>(false);
    const container                         = useRef<HTMLDivElement>();
    const selector: gsap.utils.SelectorFunc = gsap.utils.selector(container);

    useGSAP(() => {
        if (!container.current || (!showMenu && !optionMenu)) {
            return;
        }

        gsap.to(selector('.widget-menu'), {
            duration: 0.5,
            display: showMenu ? 'initial' : 'none',
            opacity: showMenu ? 1 : 0,
            x: showMenu ? 0 : '20%',
            y: showMenu ? 0 : '-20%',
            scale: showMenu ? 1 : 0.8,
            ease: 'Expo.easeInOut',
        });
    }, { dependencies: [showMenu], scope: container });

    const handleInputChange = (e) => {
        updatePeriod(e.label);
    };

    useEffect(()=>{
        if(setContainer){
           setContainer(container);
        }
    },[container.current]);

    const menuOptionStyle = { color: optionColor ? optionColor : '' };
    const headerPadding   = manualBodyPadding && !noHeaderPadding ? '15px 20px 0 20px' : '';
    const footerPadding   = manualBodyPadding && !noFooterPadding ? '0 20px 15px 20px' : '';

    const shouldContainError: { label: string; error: string } = useMemo(() => (
        !disableErrorChecking && errorTracker.length ? errorTracker.filter((item) => {
            const label    = item.label.toLowerCase();
            const comTitle = String(title).toLowerCase();

            if (
                label === comTitle ||
                titleMapper[comTitle] === label ||
                errorWrapperTitler === label ||
                titleMapper[errorWrapperTitler] === label
            ) {
                return item;
            }
        })[0] : null
    ), [errorTracker]);

    useEffect(() => setShowMenu(false), [menuStatus]);

    return (
        <div
            data-testid="wg-container"
            className={'widget-container'}
            style={{
                // padding: containerPadding,
                position: containerPositionUnset ? 'static' : 'relative'
            }}
            ref={container}
            id={id}
        >
            {isLoading && <LoaderWrapper />}

            {!hideHeader && (
                <OverviewFeatureWrapperHeader
                    headerPadding={headerPadding}
                    showTitle={showTitle}
                    title={title}
                    besideTitleText={besideTitleText}
                    centerTitle={centerTitle}
                    loaderColor={loaderColor}
                    disableOptions={disableOptions}
                    menuOptionStyle={menuOptionStyle}
                    setShowMenu={setShowMenu}
                    menuStatus={showMenu}
                    optionMenu={optionMenu}
                    noPadding={noHeaderPadding}
                />
            )}

            <div
                data-testid="wg-body"
                style={{ height: bodyHeight }}
                className={'widget-body' + (noBodyPadding ? ' widget-no-padding' : '')}
                ref={bodyRef}
            >
                {/* {!disableErrorChecking && shouldContainError && shouldContainError.error ? (
                    <ErrorWrapper
                        title={shouldContainError.label}
                        message={ApiErrorMessage}
                        trigger={true}
                        scaleDownSize={scaleDownErrorWrapperSize}
                    />
                ) : null} */}
                {children}
            </div>

            {!hideFooter && (
                <OverviewFeatureWrapperFooter
                    footerPadding={footerPadding}
                    disablePeriod={disablePeriod}
                    handleInputChange={handleInputChange}
                    disableOverview={disableOverview}
                    overviewStyle={overviewStyle}
                    seeAllRoute={seeAllRoute}
                    seeAllRouteText={seeAllRouteText}
                    noPadding={noFooterPadding}
                />
            )}
        </div>
    );
}

OverviewFeatureWrapper.defaultProps = {
    errorWrapperTitler: '',
    updatePeriod: null,
    loaderColor: '',
    overviewStyle: {},
    optionColor: '',
    optionMenu: null,
    bodyHeight: '',
    disableOptions: false,
    disablePeriod: false,
    disableOverview: false,
    menuStatus: false,
};

export default connect(mapStateToProps, null)(OverviewFeatureWrapper);
