import { ThemeColors } from '@constants/colors';
import { BaseSvgComponentProps } from '@shared/interfaces/component/baseSvgComponentProps';

export const SortTriangle = ({ isActive = true, ...rest }: BaseSvgComponentProps) => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" {...rest}>
        <path
            d="M5 6L0.669874 -3.32215e-07L9.33013 -9.09056e-07L5 6Z"
            fill={isActive ? ThemeColors.themeHighlightColor : '#CED2F0'}/>
    </svg>
);
