import { SVGAttributes } from 'react';

const FacebookShare = (props: SVGAttributes<SVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <g clipPath="url(#clip0_9283_5226)">
            <path d="M13.0002 4V8C6.42519 9.028 3.98019 14.788 3.00019 20C2.96319 20.206 8.38419 14.038 13.0002 14V18L21.0002 11L13.0002 4Z" stroke="#5079E4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9283_5226">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default FacebookShare;
