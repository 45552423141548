import { ReactNode } from 'react';

import './IDropdownItem.scss';

interface Props {
    title: string;
    icon?: ReactNode;
    image?: string;
}

const IDropdownItem = ({ icon, image, title }: Props) => (
    <div className="i-dropdown-item">
        {icon && icon}
        {image && <img src={image} alt="dropdown item thumbnail" />}
        <p>{title}</p>
    </div>
);

export default IDropdownItem;
