import classNames from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Group, Option } from 'react-dropdown';

import { IDropdown, IDropdownItem, Show, TablePaginationWrapper } from '@components/common';
import { TablePostVue, TableView } from '@components/svg';
import { SocialMediaLogos } from '@constants/socialMedia';
import { FacebookPostSort, FacebookPostTypes } from '@shared/enum';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import { LoaderWrapper } from '@components/widgets';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import DateInstance from '@services/date/date';
import { isValidNumber } from '@services/validation/validation';
import { DataStatus } from '@shared/enum/dataStatus';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { fetchSocialMediaSingleAllPosts } from '@store/social-media/single/SocialMediaSingle.thunk';
import { useSearchParams } from 'react-router-dom';
import FacebookPostPopup from '../facebook-post-popup/FacebookPostPopup';
import { SMSFacebookPostItem } from '../facebook-posts-swiper';
import './FacebookAllPostsView.scss';
import FacebookAllPostsTable from './table/FacebookAllPostsTable';



interface Props {
    networkType: SocialMediaTypes;
    profileId: string;
}
type View = 'post' | 'table';

const sortMapper                                = {
    [FacebookPostSort.mostRecent]: 'Sort by most recent',
    [FacebookPostSort.leastRecent]: 'Sort by least recent',
    [FacebookPostSort.mostReached]: 'Sort by most reached',
    [FacebookPostSort.leastReached]: 'Sort by least reached',
    [FacebookPostSort.mostEngagement]: 'Sort by most engagement',
    [FacebookPostSort.leastEngagement]: 'Sort by least enagegement',
};

const postTypeMapper                                = {
    [FacebookPostTypes.all]: 'All',
    [FacebookPostTypes.photos]: 'Photos',
    [FacebookPostTypes.videos]: 'Videos',
    [FacebookPostTypes.status]: 'Status',
    [FacebookPostTypes.reels]: 'Reels',
    [FacebookPostTypes.links]: 'links',
};
const postTypeDropdownDefault                       = {
    label: <IDropdownItem title={'Sort post types'} />,
    value: FacebookPostTypes.none,
};
const postTypeDropdown: (string | Option | Group)[] = Object.keys(postTypeMapper).map(key => ({
    label: <IDropdownItem title={postTypeMapper[key]} />,
    value: key,
}));

const FacebookAllPostsView = ({ networkType, profileId }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams({ sp: null });
    const selectedPost                    = searchParams.get('sp');
    const [nbResultsPerPage, setNbResultsPerPage]    = useState(10);
    const [currentPage, setCurrentPage]              = useState(1);
    const [resetCurrentPage, toggleResetCurrentPage] = useState(false);

    const sortDropdownDefault                       = {
        label: <IDropdownItem title={sortMapper[FacebookPostSort.mostRecent]} image={SocialMediaLogos?.[networkType]?.rounded} />,
        value: FacebookPostSort.mostRecent,
    };
    const sortDropdown: (string | Option | Group)[] = Object.keys(sortMapper).map(key => ({
        label: <IDropdownItem title={sortMapper[key]} />,
        value: key,
    }));

    const [viewStyle, setViewStyle] = useState<View>('post');
    const [sort, setSort]           = useState<typeof sortDropdownDefault>(sortDropdownDefault);
    const [postType, setPostType]   = useState<typeof postTypeDropdownDefault>(postTypeDropdownDefault);

    const { dates, data: posts, status } = useAppSelector((state) => ({
        ...selectFromDatepicker.dates(state),
        ...selectFromSocialMediaSingle.allPosts(state),
    }));
    const dispatch                       = useAppDispatch();

    useEffect(() => {
        const source     = generateNewCancelTokenSource();
        const [from, to] = dates;

        dispatch(fetchSocialMediaSingleAllPosts({
            data: {
                id: profileId,
                start_date: String(from),
                date_end: String(to),
                page: 1,
                limit: nbResultsPerPage,
                sortby: sort.value,
                type: postType.value,
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => source.cancel();
    }, [dates, profileId, sort, postType, nbResultsPerPage]);

    const shouldShowPostsPreview = isValidNumber(selectedPost) && !!posts?.find(post => String(post.id) === selectedPost);
    const nbPages = 1;

    return (
        <>
            <div className="widget-filters">
                {status === DataStatus.LOADING && <LoaderWrapper />}

                <div className="widget-f-left">
                    <Show.When isTrue={viewStyle === 'post'}>
                        <IDropdown
                            className='smsfp-sort-filter'
                            value={sort}
                            options={sortDropdown}
                            onChange={(option) => {
                                setSort({
                                    label: (
                                        <IDropdownItem
                                            title={sortMapper[option.value]}
                                            image={SocialMediaLogos.facebook.rounded}
                                        />
                                    ),
                                    value: option.value as FacebookPostSort,
                                });
                            }}
                        />
                    </Show.When>
                    <IDropdown
                        className='smsfp-post-type-filter'
                        value={postType}
                        options={postTypeDropdown}
                        onChange={(option) => {
                            setPostType(option as typeof postTypeDropdownDefault);
                        }}
                    />
                </div>
                <div className="widget-f-right">
                    <TableViewWrapper active={viewStyle === 'post'}>
                        <TablePostVue
                            isActive={viewStyle === 'post'}
                            onClick={() => setViewStyle('post')}
                        />
                    </TableViewWrapper>
                    <TableViewWrapper active={viewStyle === 'table'}>
                        <TableView
                            isActive={viewStyle === 'table'}
                            onClick={() => setViewStyle('table')}
                        />
                    </TableViewWrapper>
                </div>
            </div>
            <div className="widget-posts">
                <Show.When isTrue={posts?.length && shouldShowPostsPreview}>
                    <FacebookPostPopup
                        networkType={networkType}
                        posts={posts}
                        onChange={(id: string) => {
                            setSearchParams({ sp: id });
                        }}
                    />
                </Show.When>
                <Show.When isTrue={viewStyle === 'post'}>
                    {posts?.map((post, idx) => (
                        <SMSFacebookPostItem
                            key={`${ post.id }-${idx}`}
                            canClick
                            onClick={(id: string) => {
                                setSearchParams({ sp: id });
                            }}
                            id={String(post.id)}
                            comments={post.comments}
                            image={post.image}
                            likes={post.reactions}
                            publishDate={DateInstance.formatDate({
                                date: DateInstance.newDate(post.date),
                                format: 'dd MMMM yyyy - hh:mm a',
                            })}
                            shares={post.shares}
                            title={post.message}
                        />
                    ))}
                </Show.When>
                <Show.When isTrue={viewStyle === 'table'}>
                    <FacebookAllPostsTable 
                        nbResultsPerPage={nbResultsPerPage}
                        currentPage={currentPage}
                        resetCurrentPage={resetCurrentPage}
                        
                        setNbResultsPerPage={setNbResultsPerPage}
                        setCurrentPage={setCurrentPage}
                        toggleResetCurrentPage={toggleResetCurrentPage}

                        profileId={profileId} 
                        postType={postType.value}
                    />
                </Show.When>

            </div>
            <TablePaginationWrapper 
                setNbResults={(nbResults) => setNbResultsPerPage(nbResults)}

                onCurrentPageChange={(currentPage) => setCurrentPage(currentPage)}
                resetCurrentPage={resetCurrentPage}
                nbPages={nbPages}
            />
        </>
    );
};

const TableViewWrapper = ({ children, active }: PropsWithChildren<{ active: boolean }>) => (
    <div className={classNames('widget-view-wrapper', { active })}>
        {children}
    </div>
);

export default FacebookAllPostsView;
