import { ThemeColors } from '@constants/colors';
import { BaseSvgComponentProps } from 'shared/interfaces/component/baseSvgComponentProps';

const TableView = ({ isActive, ...rest }: BaseSvgComponentProps) => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" {...rest}>
        <path
            d="M0 0V13H17V0H0ZM4 1V4H1V1H4ZM1 8V5H4V8H1ZM1 9H4V12H1V9ZM16 12H6V9H16V12ZM16 8H6V5H16V8ZM16 4H6V1H16V4Z"
            fill={isActive ? ThemeColors.themeStatsColor2 : ThemeColors.inputText}
        />
    </svg>
);

export default TableView;
