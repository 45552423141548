/**
 * Pagination algorithm for table pagination
 * @param {Number} c first element
 * @param {Number} m last element
 * @returns {Array<Number | String>} pagination range
 */
export const pagination = (c: number, m: number): (number | string)[] => {
    const current                            = c;
    const last                               = m;
    const delta                              = 2;
    const left                               = current - delta;
    const right                              = current + delta + 1;
    const range: number[]                    = [];
    const rangeWithDots: (number | string)[] = [];
    let l: number;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }

        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};
