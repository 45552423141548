const hours      = Array.from({ length: 24 }, (_, i) =>
    `${String(i).padStart(2, '0')}:00`
);
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reverse();

const formatHeatmapData = (data) => {
    const dataMatrix = Array(7)
        .fill(null)
        .map(() => Array(24).fill(null));
    const chartData  = data.series[0];

    chartData?.data?.forEach(({ value }) => {
        const [hour, day, heatmapValue] = value;

        if (day < 7 && hour < 24) {
            dataMatrix[day][hour] = heatmapValue;
        }
    });

    return dataMatrix;
};

function convertToCsvPieChart(series){
    let csv             = 'Category';

    series.forEach(serie => {
        const { data } = serie;

        data.forEach(d => {
            csv += `,${d.name}`;
        });

    });
    csv += '\n';
    csv += 'value';
    series.forEach(serie => {
        const { data } = serie;

        data.forEach(d => {
            csv += `,${d.value}`;
        });
    });

    return csv;
}


function convertToCsvHeatMapChart(data){
    const dataMatrix = formatHeatmapData(data);
    let csvContent   = 'Week Days,' + hours.join(',') + '\n';

    daysOfWeek.forEach((day, i) => {
        const row  = [day, ...dataMatrix[i].map(val => (val !== null ? val : ''))].join(',');

        csvContent += row + '\n';
    });

    return csvContent;
}

function convertToCsvCommunChart(data,showFirstSeries = false){
    if (!data) {return '';}


    let series: any[] = data?.series;
    let csv           = 'Category';

    if (showFirstSeries){
        series = [data.series[0]];
    }

    series.forEach(series => {
        csv += `,${series.name}`;
    });
    csv              += '\n';



    let categories = data.xAxis ? data.xAxis[0].data : [];

    if (!categories){
        categories = series[0].data.map((el,i)=> i);
    }

    const seriesData = series;

    for (let i = 0; i < categories.length; i++) {
        csv += `${categories[i]}`;
        seriesData.forEach((series) => {
            if (typeof series.data[i] == 'object' && series.data[i]){
                csv += `,${series.data[i].value}`;
            }
            else {
                csv += `,${series.data[i]}`;
            }
        });
        csv += '\n';
    }

    return csv;
}


function convertToCsvGaugeChart(series){
    let csv         = 'value\n';
    const { value } = series[0].data[0];

    csv += value;

    return csv;
}



function convertToCSV(data,showFirstSeries = false) {
    if (!data) {return '';}

    const series: any[] = data?.series;
    const isPie         = series.find(el=> el.type === 'pie');
    const isHeatMap     = series.find(el => el.type === 'heatmap');
    const isGauge       = series.find(el=> el.type === 'gauge');

    let csv = '';

    if (isGauge){
        csv = convertToCsvGaugeChart(series);

        return csv;
    }

    if (isPie){
        csv = convertToCsvPieChart(series);

        return csv;
    }

    if (isHeatMap){
        csv = convertToCsvHeatMapChart(data);

        return csv;
    }


    csv = convertToCsvCommunChart(data,showFirstSeries);

    return csv;
}

export default convertToCSV;
