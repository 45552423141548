import { TablePagination, TablePaginationResultsCount } from './';

interface Props {
    nbPages: number;
    onCurrentPageChange: (value: number) => void;
    resetCurrentPage?: boolean;

    defaultSelectedNbResults?: number;
    setNbResults: (value: number) => void;
}

const TablePaginationWrapper = ({ nbPages, resetCurrentPage, onCurrentPageChange, defaultSelectedNbResults, setNbResults }: Props) => (
    <div className="cbt-table-pag-wrapper">
        <TablePaginationResultsCount setNbResults={setNbResults} defaultSelectedNbResults={defaultSelectedNbResults} />
        <TablePagination nbPages={nbPages} onCurrentPageChange={onCurrentPageChange} resetCurrentPage={resetCurrentPage} />
    </div>
);

export default TablePaginationWrapper;
