import React, { lazy } from 'react';
import { Option } from 'react-dropdown';
import { useNavigate } from 'react-router-dom';

import Dropdown from '@components/common/menus/dropdown/Dropdown';

const ArrowRightShort    = lazy(() => import('components/svg/arrows/ArrowRightShort').then(module => ({ default: module.ArrowRightShort })));

declare interface OverviewFeatureWrapperFooterProps {
    footerPadding: string;
    handleInputChange:  (arg: Option) => void;
    disablePeriod?: boolean;
    disableOverview?: boolean;
    overviewStyle?: React.CSSProperties;
    seeAllRoute?: string;
    noPadding?: boolean;
    seeAllRouteText?: string;
}

const customSelectOptions = [
    { value: 'D', label: 'Per day' },
    { value: 'W', label: 'Per week' },
    { value: 'M', label: 'Per month' },
];

const OverviewFeatureWrapperFooter = ({
    disablePeriod,
    handleInputChange,
    disableOverview,
    overviewStyle,
    footerPadding,
    seeAllRoute,
    seeAllRouteText,
    noPadding
}: OverviewFeatureWrapperFooterProps) => {
    const navigate = useNavigate();

    const handleClick = () => navigate(seeAllRoute || '/dashboard');

    return (
        <div
            data-testid="wg-footer"
            className={'widget-footer' + (noPadding ? ' widget-no-padding' : '')}
            style={{
                padding: footerPadding,
            }}
        >
            <div>
                {!disablePeriod && (
                    <div data-testid="wg-period">
                        <Dropdown
                            options={customSelectOptions}
                            value={customSelectOptions[0]}
                            onChange={handleInputChange}
                        />
                    </div>
                )}
            </div>
            {!disableOverview && (
                <div
                    data-testid="wg-overview"
                    className="widget-overview _pointer _transition"
                    onClick={handleClick}
                >
                    <p style={overviewStyle}>{seeAllRouteText ?? 'See all'}</p>

                    <ArrowRightShort
                        style={overviewStyle}
                        className="arrow _transition"
                    />
                </div>
            )}
        </div>
    );
};

export default OverviewFeatureWrapperFooter;
