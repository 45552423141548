import { toggleSiteLoader } from 'store/site-loader/siteLoader.actions';
import { useAppDispatch, useAppSelector } from '../redux/useRedux';

export default function useSiteLoader() {
    const state    = useAppSelector((state) => state.siteLoaderReducer);
    const dispatch = useAppDispatch();

    const dispatchToggle = (show: boolean, label = '', source = null) => {
        dispatch(
            toggleSiteLoader(show, label, source)
        );
    };

    return { state, dispatchToggle };
}
