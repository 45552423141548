import classNames from 'classnames';

import { Img } from '@components/common';
import { EyeFilled, FacebookComment, FacebookLike, FacebookShare } from '@components/svg';

import { NoImage } from '@constants/defaults';
import './SMSFacebookPostItem.scss';

interface Props {
    id: string;
    image: string;
    publishDate: string;
    title: string;
    likes: number;
    comments: number;
    shares: number;
    canClick?: boolean;
    onClick?: (id: string) => void;
}

const SMSFacebookPostItem = ({ onClick, canClick = false, id, image, publishDate, title, likes, comments, shares }: Props) => {
    const handleClick = () => onClick?.(id);

    return (
        <div className={classNames('smsfp-item', { canClick })} onClick={() => canClick && handleClick()}>
            <div className="smsfp-hover">
                <EyeFilled />
            </div>

            <Img src={image || NoImage} alt="post thumbnail" />

            <small>{publishDate}</small>
            <p>{title}</p>

            <div className='smsfp-separator' />
            <div className="smsfp-eng">
                <EngageItem icon={<FacebookLike />} value={likes} />
                <EngageItem icon={<FacebookComment />} value={comments} />
                <EngageItem icon={<FacebookShare />} value={shares} />
            </div>
        </div>
    );
};

interface ItemProps {
    icon: JSX.Element;
    value: number;
}

const EngageItem = ({ icon, value }: ItemProps) => (
    <div className="smsfp-eng-item">
        {icon}
        <p>{value}</p>
    </div>
);

export default SMSFacebookPostItem;
