import { SVGAttributes } from 'react';

const PostViewsCompleted = (props: SVGAttributes<SVGElement>) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
        <g clipPath="url(#clip0_10409_1074)">
            <path d="M10.8281 12.9997C10.8281 13.5743 11.0564 14.1254 11.4627 14.5317C11.8691 14.9381 12.4202 15.1663 12.9948 15.1663C13.5694 15.1663 14.1205 14.9381 14.5269 14.5317C14.9332 14.1254 15.1615 13.5743 15.1615 12.9997C15.1615 12.425 14.9332 11.8739 14.5269 11.4676C14.1205 11.0613 13.5694 10.833 12.9948 10.833C12.4202 10.833 11.8691 11.0613 11.4627 11.4676C11.0564 11.8739 10.8281 12.425 10.8281 12.9997Z" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0272 19.4534C8.55617 19.1208 5.63117 16.9682 3.25 13C5.85 8.66667 9.1 6.5 13 6.5C16.9 6.5 20.15 8.66667 22.75 13C22.5224 13.3801 22.2828 13.753 22.0318 14.118" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.25 20.5837L18.4167 22.7503L22.75 18.417" stroke="#5079E4" strokeWidth="2.16667" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_10409_1074">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default PostViewsCompleted;
