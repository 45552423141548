import { useRef } from 'react';
import { useOutletContext } from 'react-router-dom';

import { FacebookAllPostsView } from '@features/social-media/single';
import { useLoadingData } from '@hooks/index';
import { SinglesLayoutContextType } from '@layouts/social-media/SocialMediaSinglesLayout';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import './FacebookAllPosts.layout.scss';

const FacebookAllPosts = () => {
    const gridWrapper               = useRef<HTMLDivElement>();

    useLoadingData(gridWrapper, 'Loading facebook posts...');

    const {
        currentProfileId
    } = useOutletContext<SinglesLayoutContextType>();

    return (
        <div className="widgets-flex --socialMediaFacebookPosts" ref={gridWrapper}>
            <div className="widget-item --noBorder">
                <FacebookAllPostsView profileId={currentProfileId} networkType={SocialMediaTypes.FACEBOOK} />
            </div>
        </div>
    );
};


export default FacebookAllPosts;
