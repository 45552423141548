import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Img from '@components/common/img/Img';
import { KeyboardArrowDownSmall } from 'components/svg/arrows/KeyboardArrowDownSmall';
import { KeyboardArrowRightMedium } from 'components/svg/arrows/KeyboardArrowRightMedium';

interface Props {
    /**
     * if highlight is passed in, only the highlight element
     * will be shown inside the td
     */
    highlight?: string;
    /**
     * if highlight is passed the showLogo will show an icon to access the current
     * item and see it's statistics
     */
    showLogo?: boolean;
    itemsNotBold?: boolean;

    title?: string;
    image?: string;
    roundImageToCircle?: boolean;
    smallText?: string;
    pageUrl?: string;
    goToLinkWhenClickedOnTdFirstChild?: boolean;
    replaceTitleWithLinkTag?: boolean;
    titleBold?: boolean;
    isDropdown?: boolean;
    firstLetterCapitalized?: boolean;
    itemOnClick?: (value?) => void;
    onClick?: (value?) => void;

    /***
     * if children is passed in, every other prop
     * will be by-passed
     */
    children?: React.ReactNode;
}

const TdItem = ({
    highlight = null,
    itemsNotBold = false,
    showLogo = false,

    image = null,
    roundImageToCircle = false,
    title = null,
    smallText = null,
    pageUrl = null,
    goToLinkWhenClickedOnTdFirstChild = false,
    replaceTitleWithLinkTag = false,
    titleBold = false,
    isDropdown = false,
    firstLetterCapitalized = false,
    itemOnClick = null,
    onClick = null,

    children
}: Props) => {
    const [dropdownClicked, setDropdownClicked] = useState(false);

    return (
        <td>
            <div>
                {
                    children ? children :
                        highlight ? (
                            <>
                                <h1 className={itemsNotBold ? 'cbt-not-bold' : ''}>{highlight}</h1>
                                {showLogo && pageUrl && (
                                    !goToLinkWhenClickedOnTdFirstChild && replaceTitleWithLinkTag ? (
                                        <a href={pageUrl} target="_blank" className={'cbt-td-go-to-link'}>
                                            <KeyboardArrowRightMedium
                                                onClick={() => onClick?.()}
                                            />
                                        </a>
                                    ) : (
                                        <NavLink to={pageUrl} className="cbt-td-go-to-link">
                                            <KeyboardArrowRightMedium
                                                onClick={() => onClick?.()}
                                            />
                                        </NavLink>
                                    )
                                )}
                            </>
                        ) : (
                            <article>
                                {image && !image?.includes('unknown') && (
                                    <div className="cbt-image-holder">
                                        <Img
                                            loaderProps={{
                                                width: 5,
                                                height: 5,
                                                nbDots: 3,
                                            }}
                                            // shouldConvertImageToDataUrl
                                            className={roundImageToCircle ? 'cbt-td-image-circle' : ''}
                                            src={image}
                                            alt={title}
                                        />
                                    </div>
                                )}

                                <div className="cbt-td-desc">
                                    <div
                                        onClick={() => {
                                            itemOnClick?.();
                                            setDropdownClicked(prev => !prev);
                                        }}
                                        style={{
                                            display: 'flex',
                                            cursor: isDropdown ? 'pointer' : '',
                                        }}
                                        className={firstLetterCapitalized ? 'cbt-td-capitalize-first-letter' : ''}
                                    >
                                        {
                                            goToLinkWhenClickedOnTdFirstChild && pageUrl ? (
                                                <NavLink to={pageUrl}>
                                                    <p className={titleBold ? 'cbt-td-bold' : ''}>
                                                        {title ? title : 'N/A'}
                                                    </p>
                                                </NavLink>
                                            ) : (
                                                replaceTitleWithLinkTag ? (
                                                    <a href={pageUrl} target="_blank" className={titleBold ? 'cbt-td-bold' : ''}>
                                                        {title ? title : 'N/A'}
                                                    </a>
                                                ) : (
                                                    <p className={titleBold ? 'cbt-td-bold' : ''}>
                                                        {title ? title : 'N/A'}
                                                    </p>
                                                )
                                            )
                                        }

                                        {isDropdown && (
                                            <KeyboardArrowDownSmall
                                                style={{
                                                    marginLeft: '10px',
                                                    transition: 'all 0.5s cubic-bezier(0.75, -0.5, 0, 1.75)',
                                                    transform:  dropdownClicked ? 'rotate(180deg)' : ''
                                                }}
                                            />
                                        )}
                                    </div>
                                    {smallText && <small>{smallText}</small>}
                                </div>
                            </article>
                        )
                }
            </div>
        </td>
    );
};

export default TdItem;
