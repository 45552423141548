import { useEffect, useMemo } from 'react';


import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { SocialMediaSinglePost } from '@shared/interfaces/social-media/socialMediaSingle';
import { selectFromDatepicker } from '@store/date-picker/datePicker.reducer';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { fetchSocialMediaSingleAllPosts } from '@store/social-media/single/SocialMediaSingle.thunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux/useRedux';
import { symbolizeNumber } from 'services/conversion/conversion';
import DateInstance from 'services/date/date';

interface UseFacebookAllPostsTableProps {
    profileId: string;
    limit: number;
    offset?: number;
    sort?: { with: string; reversed: boolean };
    postType: string;
}

export default function useFacebookAllPostsTable({ profileId, limit, offset, sort, postType }: UseFacebookAllPostsTableProps) {
    const { dates, data: posts, status, error } = useAppSelector((state) => ({
        ...selectFromDatepicker.dates(state),
        ...selectFromSocialMediaSingle.allPosts(state),
    }));
    const dispatch                              = useAppDispatch();

    useEffect(() =>  {
        const source     = generateNewCancelTokenSource();
        const [from, to] = dates;

        dispatch(fetchSocialMediaSingleAllPosts({
            data: {
                id: profileId,
                start_date: String(from),
                date_end: String(to),
                page: 1,
                limit: 20,
                sortby: sort.with,
                type: postType,
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => source.cancel();
    }, [dates, profileId, limit, offset, sort]);


    const { nbPages, bodyItems } = useMemo(() => {
        if (!posts || !posts?.length) {
            return { nbResults: 0, bodyItems: null };
        }

        const filteredItemsToTableItems = posts.map((item: SocialMediaSinglePost) => {
            // const url         = `${DefinedRoutes.pages.page.path}?pageUrl=${item.pageUrl}`;
            const url          = `?sp=${item.id}`;
            const reactions    = formatNumber(item.reactions);
            const comments     = formatNumber(item.comments);
            const shares       = formatNumber(item.shares);
            const impressions  = formatNumber(
                (item.impressions.organic || 0) + (item.impressions.paid || 0)
            );
            const interactions = item.interactions+'%';
            const date         = DateInstance.formatDate({ date: DateInstance.newDate(item?.date), format: 'MMMM dd yyyy' });

            return [
                {
                    image: item.image,
                    title: item.message,
                    // smallText: DateInstance.formatDate({ date: DateInstance.newDate(item?.date), format: 'MMMM dd yyyy' }),
                    pageUrl: url
                },
                date,
                reactions,
                comments,
                shares,
                impressions,
                [
                    interactions,
                    url
                ]
            ];
        });

        return {
            nbPages: 0,
            bodyItems: filteredItemsToTableItems
        };
    }, [posts]);

    return { bodyItems, nbPages, status, error };
}

const formatNumber = (number) => symbolizeNumber(Number(number|| 0), 1, {
    convertToComma: true,
    shouldRound: false,
    ignoreFirstSixDigits: true,
});
