import classNames from 'classnames';

import { Img } from '@components/common';
import { SocialMediaProfileAvatarSM } from '@components/common/menus';
import { Impressions, Interactions, Reach } from '@components/svg';
import { SocialMediaTypes } from '@shared/enum/socialMediaTypes';

import { useAppDispatch, useAppSelector } from '@hooks/index';
import { generateNewCancelTokenSource } from '@services/axios/axiosUtils';
import { selectFromSocialMediaSingle } from '@store/social-media/single/SocialMediaSingle.slice';
import { fetchSocialMediaSinglePost } from '@store/social-media/single/SocialMediaSingle.thunk';
import { useEffect } from 'react';
import FPPDetailsSection, { FacebookPostPopupSlideItemMetricGroup } from '../details-section/FPPDetailsSection';
import FPPHighlightItem from '../highlight-item/FPPHighlightItem';
import './FacebookPostPopupSlideItem.scss';

export interface FacebookPostPopupSlideItemDetails {
    networkType: SocialMediaTypes;
    profileThumb: string;
    profileUsername: string;
    profileUrl: string;
    groupUrl?: string;
    sharedIn: string;
    publishDate: string;
    postThumb: string;
    postContent: string;
}
export interface FacebookPostPopupSlideItemHighlightsItem {
    value: number;
    paid?: number;
    unpaid?: number;
}
export interface FacebookPostPopupSlideItemHighlights {
    impressions: FacebookPostPopupSlideItemHighlightsItem;
    reach: FacebookPostPopupSlideItemHighlightsItem;
    interactions: FacebookPostPopupSlideItemHighlightsItem;
}

interface Props {
    id: string;
    details: FacebookPostPopupSlideItemDetails;
    highlights: FacebookPostPopupSlideItemHighlights;
    interactions: FacebookPostPopupSlideItemMetricGroup[];
    className?: string;
}

const FacebookPostPopupSlideItem = ({  id, details, highlights, interactions, className }: Props) => {
    const { data, status } = useAppSelector(selectFromSocialMediaSingle.activePost);
    const dispatch         = useAppDispatch();

    useEffect(() => {
        const source = generateNewCancelTokenSource();

        dispatch(fetchSocialMediaSinglePost({
            data: {
                id
            },
            config: {
                cancelToken: source.token
            }
        }));

        return () => source.cancel();
    }, [id]);

    console.log('here:', data);

    return (
        <div className={classNames('fppi-container', { [className]: !!className })}>
            <article className="fppi-left">
                <div className="fppi-head">
                    <SocialMediaProfileAvatarSM
                        networkType={details.networkType}
                        thumbnail={details.profileThumb}
                        className='fppi-avatar'
                    />
                    <div className='fppi-details'>
                        <p>
                            <a target="_blank" href={details.profileUrl}>{details.profileUsername}</a>
                            <span>shared in </span>
                            <a
                                target="_blank"
                                style={!details?.groupUrl ? { cursor: 'auto', textDecoration: 'none' }: {}}
                                href={details?.groupUrl || null}
                            >{details.sharedIn}</a>
                        </p>
                        <p>{details.publishDate}</p>
                    </div>
                </div>

                {details.postThumb && <Img src={details.postThumb} alt="post thumbnail" />}
                <p>{details.postContent}</p>
            </article>

            <div className="fppi-right">
                <div className="fppi-highlights">
                    <FPPHighlightItem
                        icon={<Impressions />}
                        title={'Impressions'}
                        value={highlights.impressions.value}
                        paid={highlights.impressions?.paid}
                        unpaid={highlights.impressions?.unpaid}
                    />
                    <FPPHighlightItem
                        icon={<Reach />}
                        title={'Reach'}
                        value={highlights.reach.value}
                        paid={highlights.reach?.paid}
                        unpaid={highlights.reach?.unpaid}
                        theme='yellow'
                    />
                    <FPPHighlightItem
                        icon={<Interactions />}
                        title={'Interactions'}
                        value={highlights.interactions.value}
                        paid={highlights.interactions?.paid}
                        unpaid={highlights.interactions?.unpaid}
                        theme="blue"
                    />
                </div>

                <div className="fppi-interactions">
                    {interactions.map((group, idx) => (
                        <FPPDetailsSection
                            key={`${Math.random() * 1210}-${idx}`}
                            items={group.items}
                            title={group.title}
                            addSeparator={idx !== interactions.length - 1}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FacebookPostPopupSlideItem;
