import React from 'react';

import { Search } from 'components/svg/Search';

interface Props {
    onChange?: React.FormEventHandler<HTMLInputElement>;
    onKeyUp?: React.FormEventHandler<HTMLInputElement>;
}

function TableSearch ({ onChange, onKeyUp }: Props) {
    return (
        <div className="cbt-search-wrapper">
            <input
                type="text"
                autoComplete="off"
                defaultValue={''}
                onChange={onChange}
                onKeyUp={onKeyUp}
                placeholder="Search.."
            />

            <Search className='header-search-icon' />
        </div>
    );
}

export default TableSearch;
